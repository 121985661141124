<header>
  <nav
  class="navbar navbar-expand-md navbar-toggleable-md navbar-light  border-bottom box-shadow mb-0 position-relative"
>
  <div class="container">
  <div class="row justify-content-start ml-3 banner-bar" style="background-color: #5d5c61;">
    <div class="col-sm-6 col-lg-4">
    <a *ngIf="!loggedIn()" class="navbar-brand ml-3 d-md-block" [routerLink]="['/']"><img class="fixed-width-height-logo " src="../../assets/logo1.png" height="70" /></a>
    <a *ngIf="loggedIn()" class="navbar-brand ml-3 d-md-block" [routerLink]="['/welcome']"><img class="fixed-width-height-logo " src="../../assets/logo1.png" height="70" /></a>
  </div>
  </div>


    


  <ul class="navbar-nav flex-grow">


    <li *ngIf="!loggedIn()"
        class="nav-item"
        [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }">
      <a class=" text-white mr-2 " [routerLink]="['/learn-more']">ABOUT INTERCESSORY Prayer Training</a> <span class="text-white "> &nbsp; &nbsp;</span>

    </li>

    <li *ngIf="loggedIn()"
        class="nav-item"
        [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }">
      <a class=" text-warning mr-2 " [routerLink]="['/classes']">MY CLASSES</a> 
      <span class="text-white "> | </span>

    </li>

    <li *ngIf="loggedIn()"
        class="nav-item">
      <a class=" text-white ml-2 " href="https://fliphtml5.com/qubm/bklt" target="_blank">&nbsp;FAQ's&nbsp;</a> 
      <span class="text-white "> | </span>
    </li>

    <li *ngIf="loggedIn()"
    class="nav-item">
      <a class=" text-white ml-2 " href="https://fliphtml5.com/qubm/rlzm" target="_blank">&nbsp;E-Book&nbsp;</a> 
      <span class="text-white "> | </span>
    </li>
    

    
    <li *ngIf="courseCompleted()"
        class="nav-item"
        [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }">
        <span class="text-white "> | </span>
      <a class=" text-white ml-2 " [routerLink]="['/dashboard']">CERTIFICATE</a> 
    </li>
    

    <li *ngIf="!loggedIn()" class="nav-item"
        [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }">
      <!--<a class=" text-white ml-1 " href="https://www.livingwd.org" target="_blank">LIVING WORD</a>-->
      <a class=" text-white ml-2 " [routerLink]="['/contact-us']"> NEED HELP?</a>
    </li>
  </ul>



  <!--<a class="navbar-brand" [routerLink]="['/']">Lwcc.Intercessory.Web</a>-->
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          aria-label="Toggle navigation"
          [attr.aria-expanded]="isExpanded"
          (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse collapse d-md-inline-flex flex-md-row-reverse"
       [ngClass]="{ show: isExpanded }">
    <ul class="navbar-nav flex-grow">

    <li class="nav-item mr-0" *ngIf="loggedIn()">
        <circle-progress #circleProgress [percent]=" getPercentage()" [radius]="options.radius"
                         [titleFontSize]="10"
                         [unitsFontSize]="10"
                         [showZeroOuterStroke]=true
                         [showInnerStroke]=true
                         [showSubtitle]=false
                         [innerStrokeColor]="'#ffffff'"
                         [backgroundColor]="'#ffffff'"
                         [backgroundGradient]=true
                         [backgroundGradientStopColor]="'#c0c0c0'"
                         [outerStrokeGradient]=false
                         [outerStrokeColor]="'#61a9dc'"
                         [outerStrokeWidth]=2
                         [innerStrokeWidth]=0
                         [animation]="false"
                         [animationDuration]="0"
                         [backgroundStrokeWidth]=-1
                         [backgroundPadding]=-4></circle-progress>
      </li>
    
      <li *ngIf="loggedIn()" class="nav-item">
        <a class="btn text-white  ml-0">Your progress</a> 
      </li>

      <li *ngIf="!loggedIn()"

          class="nav-item"
          [routerLinkActive]="['link-active']"
          [routerLinkActiveOptions]="{ exact: true }">
        <a class="btn btn-primary text-white mr-2 " [routerLink]="['/login']">Log In</a>

      </li>

      <li *ngIf="!loggedIn()" class="nav-item" [routerLinkActive]="['link-active']">
        <a class="btn btn-success text-white " [routerLink]="['/register']">Sign Up</a>
      </li>

      <li *ngIf="loggedIn()" class="nav-item" [routerLinkActive]="['link-active']">
        <a class="btn btn btn-light text-dark mr-2" (click)="logout()">Logout</a>
      </li>
      


      <!--<div *ngIf="loggedIn()" ngbDropdown class="d-inline-block  mt-2 mt-lg-0 ">
        <a class="dropdown-toggle text-charcol" id="dropdownBasic1" ngbDropdownToggle> <i class="fa fa-user fa-2x"></i> user</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="dropdown-item" [routerLink]="['user-create']">Dashboard</button>
          <button class="dropdown-item" [routerLink]="['user-list']">Preferences</button>
        </div>
      </div>-->

      <!--<li *ngIf="loggedIn()" class="nav-item" [routerLinkActive]="['link-active']">
fggg
<a class="dropdown-toggle text-charcol" id="dropdownBasic1" ngbDropdownToggle> <i class="fa fa-user">DDD</i> {{ this.authService.currentUser['firstname'] }}</a>
<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
  <button class="dropdown-item" [routerLink]="['user-create']">Dashboard</button>
  <button class="dropdown-item" [routerLink]="['user-list']">Preferences</button>
</div>
</li>-->

    </ul>
  </div>

  <div *ngIf="!isLoading" class="position-absolute end-0 px-4">
    <div *ngIf="loggedIn()">
      <li *ngIf="this.isAdmin" class="nav-item admin-circle" [routerLinkActive]="['link-active']">
        <a class="btn btn-light text-dark" [routerLink]="['/admin-dashboard']"></a>
      </li>
    </div>
  </div>

</div>
</nav>
</header>
