
<div class="container container_no_spaces">

  <div class="row justify-content-center  row-register2">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
      <form class="container container_inner mt-3" [formGroup]="contactForm" (ngSubmit)="contactUs()">
        <!--div class="row justify-content-center text-center ">
      <div class="col col-sm-6 justify-content-center py-5">Register</div>
  </div-->
        <div class="form-group ">
          <h4 class="text-blue"> Contact Us </h4>
        </div>


        <div class="form-group  ">
          <!--<label for="firstname " class="form-label">Firts Name</label>-->
          <input formControlName="firstname"
                 [ngClass]="{'is-invalid': contactForm.get('firstname').errors && contactForm.get('firstname').touched}"
                 type="text" class="form-control" id="firstname" placeholder="First Name">

          <div class="invalid-feedback" *ngIf="contactForm.get('firstname').touched && contactForm.get('firstname').hasError('required')">First name is requiered</div>

        </div>
        <div class="form-group">
          <input formControlName="lastname"
                 [ngClass]="{'is-invalid': contactForm.get('lastname').errors && contactForm.get('lastname').touched}"
                 type="text" class="form-control" id="lastname" placeholder="Last Name">

          <div class="invalid-feedback" *ngIf="contactForm.get('lastname').touched && contactForm.get('lastname').hasError('required')">Last name is requiered</div>
        </div>
        <div class="form-group">

          <input formControlName="email"
                 [ngClass]="{'is-invalid': contactForm.get('email').errors && contactForm.get('email').touched}"
                 type="email" class="form-control" id="email" placeholder="E-Mail Address">
          <div class="invalid-feedback" *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('required') ;else content">Email is requiered</div>
          <ng-template #content>
            <div class="invalid-feedback" *ngIf="contactForm.get('email').errors">Email is not Valid</div>
          </ng-template>
        </div>

        <div class="form-group">
          <input formControlName="phone"
                 [ngClass]="{'is-invalid': contactForm.get('phone').errors && contactForm.get('phone').touched}"
                 type="text" class="form-control" id="phone" placeholder="Phone">
          <!--<div class="invalid-feedback" *ngIf="contactForm.get('phone').touched && contactForm.get('phone').hasError('required')">City is requiered</div>-->
        </div>

        <div class="form-group">
          <textarea formControlName="comment" rows="4"
                    [ngClass]="{'is-invalid': contactForm.get('comment').errors && contactForm.get('comment').touched}"
                    type="text" class="form-control" id="comment" placeholder=" Your Question or Comment ">
          </textarea>

          <div class="invalid-feedback" *ngIf="contactForm.get('comment').touched && contactForm.get('comment').hasError('required')">Comment is requiered</div>
        </div>



        <div class=" text-center">

          <button class="btn btn-success" [disabled]="!contactForm.valid" type="submit">Submit</button>
        </div>
      </form>
    </div>

  </div>
</div>
