
<div class="container container_no_spaces">

  <div class="row justify-content-center  row-register2">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
      <form class="container container_inner mt-3" [formGroup]="registerForm" (ngSubmit)="register()">
        <!--div class="row justify-content-center text-center ">
      <div class="col col-sm-6 justify-content-center py-5">Register</div>
  </div-->
        <div class="form-group ">
          <h4 class="text-blue"> Sign Up </h4>
        </div>


        <div class="form-group  ">
          <!--<label for="firstname " class="form-label">Firts Name</label>-->
          <input formControlName="firstname"
                 [ngClass]="{'is-invalid': registerForm.get('firstname').errors && registerForm.get('firstname').touched}"
                 type="text" class="form-control" id="firstname" placeholder="First Name">

          <div class="invalid-feedback" *ngIf="registerForm.get('firstname').touched && registerForm.get('firstname').hasError('required')">First name is requiered</div>

        </div>
        <div class="form-group">
          <!--<label for="lastname">Last Name</label>-->
          <input formControlName="lastname"
                 [ngClass]="{'is-invalid': registerForm.get('lastname').errors && registerForm.get('lastname').touched}"
                 type="text" class="form-control" id="lastname" placeholder="Last Name">

          <div class="invalid-feedback" *ngIf="registerForm.get('lastname').touched && registerForm.get('lastname').hasError('required')">Last name is requiered</div>
        </div>
        <div class="form-group">
          <!--<label for="email">Email address</label>-->
          <!--<fa-icon [icon]="filmIcon" size="2x"></fa-icon>
    <fa-icon [icon]="faEnvelopeIcon" size="3x"></fa-icon>-->

          <input formControlName="email"
                 [ngClass]="{'is-invalid': registerForm.get('email').errors && registerForm.get('email').touched}"
                 type="email" class="form-control" id="email" placeholder="E-Mail Address">
          <div class="invalid-feedback" *ngIf="registerForm.get('email').touched && registerForm.get('email').hasError('required') ;else content">Email is requiered</div>
          <ng-template #content>
            <div class="invalid-feedback" *ngIf="registerForm.get('email').errors">Email is not Valid</div>
          </ng-template>
        </div>

        <!--countries-->
        <div class="form-group">
          <!--<label for="country">Country</label>-->
          <select formControlName="countryId" class="form-control" id="countryId" placeholder="Country">
            <option value="" disabled selected hidden>Country ...</option>
            <option *ngFor="let item of countries" [ngValue]="item.id">{{item.name}}</option>
          </select>
        </div>


        <div class="form-group">
          <input formControlName="city"
                 [ngClass]="{'is-invalid': registerForm.get('city').errors && registerForm.get('city').touched}"
                 type="text" class="form-control" id="city" placeholder="City">

          <div class="invalid-feedback" *ngIf="registerForm.get('city').touched && registerForm.get('city').hasError('required')">City is requiered</div>
        </div>

        <div class="form-group ">
          <input formControlName="password"
                 [ngClass]="{'is-invalid': registerForm.get('password').errors && registerForm.get('password').touched}"
                 type="password" class="form-control" id="newPassword" placeholder="Password">
          <div class="invalid-feedback" *ngIf="registerForm.get('password').touched && registerForm.get('password').hasError('required')"> Password is requiered</div>
          <div class="invalid-feedback" *ngIf="registerForm.get('password').touched && registerForm.get('password').hasError('minlength')">Password must be at least 6 characters long</div>
          <div class="invalid-feedback" *ngIf="registerForm.get('password').touched && registerForm.get('password').hasError('maxlength')">Password must be 12 characters or less</div>
        </div>

        <div class="form-group ">
          <!-- <input matInput placeholder="Confirm password" formControlName="confirmPassword" [errorStateMatcher]="matcher"> -->
          <input matInput formControlName="confirmPassword"
                 [ngClass]="{'is-invalid': registerForm.get('confirmPassword').errors
                      && registerForm.get('confirmPassword').touched}"
                 type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password">
          <div class="invalid-feedback" *ngIf="registerForm.get('confirmPassword').touched && registerForm.get('confirmPassword').hasError('required')">Confirm Password is required</div>
          <div class="invalid-feedback" *ngIf="registerForm.hasError('distinctPasswordsError')  && registerForm.get('password').dirty ">Passwords must match</div>
          <!-- <div class="invalid-feedback" *ngIf="registerForm.get('confirmPassword').touched && registerForm.get('confirmPassword').hasError('compareTwoPasswords')">xxxxx</div> -->
          <!-- <div class="invalid-feedback" *ngIf="registerForm.get('confirmPassword').touched && registerForm.get('confirmPassword').hasError('maxlength')">max length</div> -->
          <!-- <div class="invalid-feedback" *ngIf="registerForm.get('confirmPassword').touched && (registerForm.get('password').value !== registerForm.get('confirmPassword').value)">compare</div> -->
        </div>


        <div class=" text-center">
          <!--<div class="invalid-feedback-form" *ngIf="registerForm.hasError('distinctPasswordsError')  && registerForm.get('password').dirty ">
      Password and Confirm Password must be the same
    </div>-->
          <button class="btn btn-success" [disabled]="!registerForm.valid" type="submit">Sign Up</button>
          <!--button type="submit" class="btn btn-primary">Create Account</button>-->
        </div>

        <div class=" text-center">
          <a [routerLink]="['/login-help']"><span class="font-weight-bold">Forgot Password</span></a>
          <span> | </span>
          <a [routerLink]="['/contact-us']"><span class="font-weight-bold text-dark">Need Help?</span></a>

        </div>
      </form>
    </div>

  </div>
</div>
