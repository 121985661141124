import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth.service";
import { CourseService } from "./services/course.service";


/*
@Injectable()
export class LoginActivate implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['login']);
    }
    return true;
  }


  checkLogin(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
    //url: any
    ): Observable<boolean>|Promise<boolean>|boolean {
    if (this.authService.loggedIn()) {
      const userRole = this.authService.getRole();
      if (!(userRole == 1)) {
        this.router.navigate(['/home']);
        return false;
      }
      console.log("User Role: " + userRole);
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }


}*/


@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {


  constructor(private authService: AuthService, private courseService: CourseService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //Start Custom Route Guard
      if (!this.authService.loggedIn()) {
        this.router.navigate(['login']);
      }
      // if (!this.courseService.GetActiveCourse()) {
      //   this.router.navigate(['/construction']);
      // }
      return true;
      //End Custom Route Guard
      //let url: string = state.url;
    //return this.checkUserLogin(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

//   checkConstruction(route: ActivatedRouteSnapshot, url: any): boolean {
//   if (this.authService.loggedIn()) {
//     const userRole = this.authService.getRole();
//     console.log(route.data.role);
//     console.log(route.data.role.indexOf(userRole));
//     console.log(userRole);
//     if (route.data.role && route.data.role.indexOf(userRole) === -1) {
//       this.router.navigate(['/home']);
//       return false;
//     }
//     return true;
//   }

//   this.router.navigate(['/home']);
//   return false;
// }


}