<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
<div class="container container_no_spaces ">
  <div class="row" style=" ">
    <div class="col mx-auto" style="height: 75vh;padding: 4em 2em; background-image: url(././assets/img/helmet.png); background-position: top center ; background-repeat: no-repeat;">
      <h1 style="text-align: center; font-size: 3em; text-transform: uppercase;padding-bottom: 1em;"><b>Intercessory</b></h1>
      <h1 style="text-align: center; font-size: 2.5em;padding-bottom: 2em;"><i class="fa fa-cog" aria-hidden="true"></i><b>&nbsp;Is Under Construction&nbsp;</b><i class="fa fa-cog" aria-hidden="true"></i></h1>
      <p style="text-align: center; font-size: 1.5em;padding-bottom: 1em;"><b>We are performing scheduled maintenance on Intercessory Prayer Online.</b></p>
      <p style="text-align: center; font-size: 1.5em;padding-bottom: 1em;"><b>Your classes should be back shortly</b></p>
    </div>
  </div>

</div>
