import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';
import { Router } from '@angular/router';
import { CircleProgressComponent, CircleProgressOptions } from 'ng-circle-progress';
import { UserClassesService } from '../services/user-classes.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  isAdmin: boolean = false;
  isLoading: boolean = true;

  options = new CircleProgressOptions();
  //circle
  @ViewChild('circleProgress', { static: true }) circleProgress: CircleProgressComponent;

  constructor(public authService: AuthService, private alertify: AlertifyService,
    private router: Router, public userClassesService: UserClassesService) {

    this.options = new CircleProgressOptions();
    this.options.percent = 0;
    this.options.radius = 20;
    

  }

 

  async ngOnInit() {
    try {
      this.isAdmin = await this.authService.getAdminStatus();
    } catch (error) {
      console.error('Error fetching admin status', error);
    } finally {
      this.isLoading = false;
    }
  }


  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }


  // ngAfterViewInit(): void {
  //   this.isAdmin = null;
  //   if (this.authService.loggedIn()) {
  //       const ROLE_ADMIN = 'ROLE_ADMIN';
  //       this.isAdmin = this.authService.isAdmin;
  //       console.log(`IsAdminc: ${this.isAdmin}`);
  //   }
  // }



  // if user is loggedin
  loggedIn() {
    return this.authService.loggedIn();
  }

  courseCompleted() {
    if (this.authService.loggedIn()) {
        if (this.authService.getCourseCompleted() == "true") {
          return true;
        }
    }
    return false;
  }

  logout() {
    // remove the variables in the localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    // this.authService.currentUser = null;
    this.alertify.message('logged out');
    this.router.navigate(['/login']);
  }
  getPercentage() {
    return localStorage.getItem('percentage');
  }

}
