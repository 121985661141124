<h2>Please take the classes in order</h2>
<ul>
    <li><p>First Watch the Video</p></li>
    <li><p>Second, study the matching chapter in the E-book</p></li>
    <li><p>Third, take the quiz</p></li>
    <li><p>Last, wait one week before starting the next class</p></li>
</ul>
<h2> {{ error_message }}</h2>
<h2> {{ error_message1 }}</h2>
<button mat-button class="close-icon" [mat-dialog-close]="true">
    Close
    </button>
<!-- <button class="btn btn-primary">
    Press
</button> -->