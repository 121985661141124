import { User } from "./user";

export class UserParams {
    username: string;
    firstname: string;
    lastname: string;
    pageNumber: number;
    pageSize: number;

    // constructor(user: User) {
    constructor() {
        // this.gender = user.gender === 'female' ? 'male' : 'female';
        this.username = null;
        this.firstname = null;
        this.lastname = null;
        this.pageNumber = 1;
        this.pageSize = 5;
    }
}