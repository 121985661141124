import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from '../services/alertify.service';
import { EmailDto } from '../models/email-dto';

@Component({
  selector: 'app-login-help',
  templateUrl: './login-help.component.html',
  styleUrls: ['./login-help.component.css']
})
export class LoginHelpComponent implements OnInit {
  loginHelpForm: FormGroup;
  emailDto: EmailDto;

  constructor(private fb: FormBuilder, private router: Router,
    public authService: AuthService, private alertify: AlertifyService) { }

  ngOnInit() {
    this.createLoginHelpForm();
  }

  createLoginHelpForm() {
    this.loginHelpForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  sendEmailResetPassword() {
    this.emailDto = Object.assign({}, this.loginHelpForm.value);
    //console.log(this.emailDto);
    this.authService.sendEmailResetPassword(this.emailDto).subscribe(next => {
      this.alertify.success('If you supplied a correct username or email address then an email should have been sent to you.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        // show another page
    }
    );


  } //
}
