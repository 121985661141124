

<div class="container container_no_spaces ">
  <div class="row justify-content-center  " >
    <div class="col mb-3">
      <div id="top"></div>
    </div>

  </div>

  <div class="container bg-light ">
    <div class="row bg-info ">

      <div class="col-lg-10 col-md-10  col-sm-12 mb-2 text-center">

        <!-- <span class="text-blue" style="font-size: 2rem;font-weight:bold"> Class {{this.class?.order}} - Online Prayer Training Session #{{this.classId}} Quiz </span> -->
        <span class="text-blue" style="font-size: 2rem;font-weight:bold">Prayer Training Class #{{this.classId}} Quiz </span>
        <br />
        <!--<span class="text-white" style="font-size: 1rem; font-weight:bold">{{this.class?.description}}</span>-->

      </div>

    </div>

    <div class="row mt-1 " *ngFor="let quizQuestion of quizQuestions">
    
      <!-- steps -->
      
      <div class="col">
        <table *ngIf="this.previousQuestion != quizQuestion.question">
          <tr>
            <td>
              <span class="quizQuestionText ml-2">
                {{ quizQuestion.question}}
                {{  addCorrectAnswer(quizQuestion.quizQuestionId, quizQuestion.correctAnswer)  }}
                <!-- <div *ngIf="xxx">{{quizQuestion.correctAwnser}}</div> -->

              </span>
            </td>
          </tr>

          <tr *ngFor="let choise of quizQuestion.choises">
            <td>

              <!--<ol class="quizAnswers">
              <li *ngFor="let choise of quiz.choises">
                <input type="radio" name='{{choise.quizId}}' value='{{ choise.choiceLetter}}'>
                <span class="quizAnswerText ml-1">{{ choise.choiceLetter}} {{ choise.choiceAnswer}} </span>
              </li>-->
              <!--</span>-->
              <!--</ol>-->

              <input class="ml-3" type="radio" name='{{choise.quizQuestionId}}' value='{{ choise.choiceLetter}}' (change)="addStudentAnswer(quizQuestion.quizQuestionId, $event) ">
              <span class="quizAnswerText ml-1">{{ choise.choiceLetter}}) {{ choise.choiceAnswer}} </span>
            </td>
          </tr>

          <tr>
            <td>
              <!-- <a class=" " href="../../assets/handout/Session_1_Handout_Online.pdf" target="_blank"> -->
                <!-- <a class=" " href={{linkToHint(quizQuestion.question)}} target="_blank">
                <span class="hint ml-3">{{quizQuestion.hint}}</span>
              </a> -->
              <div class="wrong-answer ml-3" *ngIf="checkErrorQuestion(quizQuestion.quizQuestionId)"> * Wrong Answer </div>
                <div class="wrong-answer ml-3" *ngIf="checkErrorQuestion(quizQuestion.quizQuestionId)"> {{quizQuestion.correctAwnser}} </div>

              <!--<div class="wrong-answer ml-3" *ngIf="this.listQuizError.get(1)"> * Wrong Answer</div>-->
              <!--<div class="wrong-answer ml-3" *ngIf="this.listAswers.get(1)"> * Wrong Answer</div>-->


            </td>
          </tr>
        </table>
      </div>
      <!--</ng-template>-->
      {{   updateQuestion(quizQuestion.question)  }}
    </div>


   

    <div class="row mb-2 btn-continue" *ngIf="this.quizcompleted">
      <div class="col">
        <span class=" ml-3">Congratulations! You've completed the quiz.</span>
      </div>
    </div>


    <div class="row mb-2" *ngIf="!this.quizcompleted">
      <div class="col">
        <button class="bg-secondary  mb-2" (click)="validateQuiz() ">Finish Quiz</button>
      </div>
    </div>

    <div class="row mb-2" *ngIf="this.quizcompleted">
      <div class="col">
        <button class="btn-continue  mb-2" (click)="continue() ">Continue</button>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header  bg-primary text-white">
    <h4 class="modal-title bg-primary text-white" id="modal-basic-title">Intercessory Prayer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <span>Congratulations!</span>
        <br />
        <span>You have completed all classes </span>
        <br />
        <span> You will recieve an email with the Certificate of Completion</span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [show]="true" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<script>

</script>
