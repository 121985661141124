<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
<div class="container container_no_spaces">

  <div class="row justify-content-center text-center ">
    <div class="col col-sm-6 justify-content-center py-3">
      <h3> Recover Password</h3>
    </div>
  </div>
  <div class="row justify-content-center  row-register2">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
      <form class="container container_inner" [formGroup]="loginHelpForm" (ngSubmit)="sendEmailResetPassword()">

        <div class="form-group">

          <div class=" text-left">
            <span> To reset your password, submit your username or your email address below. If we can find you in the database, an email will be sent to your email address, with instructions how to get access again. </span>
          </div>
          <br />
          <!--<label for="email">Email: </label>-->
          <span class="input-group-addon"><i class="glyphicon glyphicon-envelope"></i></span>
          <input formControlName="email"
                 [ngClass]="{'is-invalid': loginHelpForm.get('email').errors && loginHelpForm.get('email').touched}"
                 type="email" class="form-control" id="email" placeholder="E-Mail Address">
          <div class="invalid-feedback" *ngIf="loginHelpForm.get('email').touched && loginHelpForm.get('email').hasError('required')">Email is requiered</div>
        </div>
        <br />
        <div class=" text-center">
          <button type="button" class="btn btn-outline-primary" [disabled]="!loginHelpForm.valid" type="submit">Reset</button>
          <!--button type="submit" class="btn btn-primary">Create Account</button>-->
        </div>


        <!--<button class="btn btn-primary" type="button" [disabled]= "this.processing" >
          <span *ngIf="this.processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Processing
        </button>-->


      </form>
    </div>

  </div>
</div>

