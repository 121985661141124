import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,  ActivatedRoute  } from '@angular/router';
import { AlertifyService } from '../services/alertify.service';
import { AuthService } from '../services/auth.service';
import { UserDto } from '../models/user-dto';
import { UserClassesService } from '../services/user-classes.service';
import { UserCourseDto } from '../models/user-course-dto';
import { UserService } from '../services/user.service';
import { CustomValidators } from '../shared/custom.validators';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  changePasswordForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed
  email: string;
  token: string;

  constructor(private fb: FormBuilder, private router: Router,
    private userService: UserService, public authService: AuthService, private alertify: AlertifyService,private actRoute: ActivatedRoute,
    private userClassesService: UserClassesService) { }

    /*
  ngOnInit() {
    this.createLoginForm();

    this.processing = true;
    if (this.authService.loggedIn()) {
      this.router.navigate(['/home']); 
    }

  }
  */
  ngOnInit() {
    this.createResetForm();
    this.email = this.actRoute.snapshot.params['email'];
    //this.token = this.actRoute.snapshot.params['token'];
  }

  getUrl() {
    return "url('../assets/sky8.jpg')";
  }
  //Assigns values to the form
  createResetForm() {
    this.changePasswordForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
      confirmnewpassword: ['', [Validators.required]]
    });
  }

  /*
  resetUserPassword() {
    this.model = Object.assign({}, this.changePasswordForm.value);

    this.authService.changePassword(this.model).subscribe(next => {
      this.alertify.success('Your password had been changed successfully.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }
  */
  resetUserPassword() {

    // console.log("1");
    this.model = Object.assign({}, this.changePasswordForm.value);
    // console.log("2");
  
    this.authService.changePassword(this.model).subscribe(next => {
      this.alertify.success('Your password had been changed successfully.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

}
