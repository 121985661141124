import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule, NgbModal, NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';


import { AppComponent } from './app.component';  
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AddToClassComponent } from './add-to-class/add-to-class.component';
import { appRoutes } from './route';
import { JwtModule } from '@auth0/angular-jwt';
import { RegisterComponent } from './register/register.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgCircleProgressModule } from 'ng-circle-progress';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { fas, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ClassesComponent } from './classes/classes-list/classes.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ConstructionComponent } from './construction-page/construction.component';
import { FooterComponent } from './footer/footer.component';
import { VideoComponent } from './video-class/video.component';
import { MessageNewUserComponent } from './message-new-user/message-new-user.component';
import { MessageSignupComponent } from './message-signup/message-signup.component';
import { ErrorInterceptorProvider } from './services/error.interceptor.service';
import { QuizzComponent } from './quizz/quizz.component';
import { QuizComponent } from './quiz/quiz.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginHelpComponent } from './login-help/login-help.component';
import { ContactUsSentComponent } from './contact-us-sent/contact-us-sent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { LoginAuthGuard } from "./LoginAuthGuard"
import { AdminAuthGuard } from "./AdminAuthGuard"
import { CheckCompletion } from './services/check-completion.service';
import { CourseService } from './services/course.service'
import { AdminNavComponent } from './admin-dashboard/admin-panel-nav/admin-panel-nav.component';
import { AdminComponent } from './admin-dashboard/admin-panel/admin-panel.component';
import { AdminClassesComponent } from './admin-dashboard/admin-panel-classes/admin-panel-classes.component';
import { AdminUsersComponent } from './admin-dashboard/admin-panel-users/admin-panel-users.component';
import { AdminUserComponent } from './admin-dashboard/admin-panel-user/admin-panel-user.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClassCardComponent } from './classes/class-card/class-card.component';
// import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import {MatDialogModule} from '@angular/material/dialog';
import { ClassPopupComponent } from './classes/class-popup/class-popup.component';
import { PopupComponent } from './popup/popup.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

//import { AlertModule, SidebarModule } from 'ng-cdbangular';
// import { getStuff } from '../services/user-classes.service';
// import { distinctPasswordsError } from './shared/CustomValidators'
// import { CompareDirective } from './shared/compare.directive'
//import { ifViewportSize } from './services/screensize.service';

/* Send Token  */
export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ConstructionComponent,
    LoginComponent,
    ChangePasswordComponent,
    RegisterComponent,
    AddToClassComponent,
    ClassesComponent,
    WelcomeComponent,
    FooterComponent,
    VideoComponent,
    MessageNewUserComponent,
    MessageSignupComponent,
    QuizzComponent,
    QuizComponent,
    ConfirmEmailComponent,
    LearnMoreComponent,
    ResetPasswordComponent,
    ContactUsComponent,
    LoginHelpComponent,
    ContactUsSentComponent,
    DashboardComponent,
    AdminNavComponent,
    AdminComponent,
    AdminClassesComponent,
    AdminUsersComponent,
    AdminUserComponent,
    ClassCardComponent,
    ClassPopupComponent,
    PopupComponent,
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,  // we use reactive forms instead of driven forms , avoid error: Can't bind to 'formGroup' since it isn't a known property of 'form'
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }), // routes
    NgbModule,
    NgbDropdownModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:57340', 'intercessory.livingwd.org', 'testintercessorylivingwd.azurewebsites.net', 'localhost:5000'], // add bearer in Headerrs
        disallowedRoutes: ['localhost:5001/api/auth']
      }
    })
    , FontAwesomeModule
    , NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }), BrowserAnimationsModule,
    MatInputModule,
    MatDialogModule,
    RxReactiveFormsModule,
    BsDropdownModule,
    PaginationModule.forRoot(),
    MatSlideToggleModule,
    
    // ModalModule,
   // , AngularFontAwesomeModule
  ],
  providers: [
    ErrorInterceptorProvider, 
    LoginAuthGuard, 
    AdminAuthGuard,
    CheckCompletion
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(fas, faEllipsisV, faFilm );
  }
}
