import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';
import { CustomValidators } from '../shared/custom.validators';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  email: string;
  token: string;

  constructor(private fb: FormBuilder, private alertify: AlertifyService, private actRoute: ActivatedRoute,
    private userService: UserService, 
    private router: Router) { }
    

  ngOnInit() {
    this.createResetForm();
    this.email = this.actRoute.snapshot.params['email'];
    this.token = this.actRoute.snapshot.params['token'];
  }

  createResetForm() {
      this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: CustomValidators.compareTwoPasswords });
  }

  resetPassword() {
    const password: any = this.resetForm.get('password').value;

    this.userService.resetPassword(this.email, password).subscribe(next => {
      this.alertify.success('Your password had been changed successfully.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }
}
