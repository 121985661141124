import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  baseUrl = environment.baseUrl + 'contactus';
  constructor(private http: HttpClient) { }

  sendEmailContactUs(contact: Contact) {
    return this.http.post(this.baseUrl, contact);
  }
}
