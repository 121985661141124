<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
<div class="container container_no_spaces ">
  <div class="row justify-content-center  ">
    <div class="col mb-3">

    </div>

  </div>

  <div class="row justify-content-center  ">
    <div class="col col-sm-10  col-md-10  col-lg-10 justify-content-center">

      <div class="container container_inner2 mb-4  ">
        <div class="row justify-content-center text-left ">
          <div class="col col-sm-10 justify-content-left py-3">
            <h2 class="mb-3">Thanks {{this.emailConfirm }} </h2>

            <span style="    font-size: 14px;  ">
            </span>

            <span style="    font-size: 14px;  ">
              Your registration has been confirmed
            </span>

            <!--<span style="    font-size: 14px;  ">
              If you do not receive the email confirmation, please add support@knowbe4.com to your
              address book and click here to resend the email. If you need help, please check out our support page.
            </span>-->

          </div>
        </div>

      </div>

    </div>
  </div>

</div>
