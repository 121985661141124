<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
 <div class="container container_no_spaces" >
    <!--<i class="fa fa-user fa-5x"></i>
    <i class="fa fa-dashboard fa-5x"></i>
    <i class="fa fa-money fa-5x"></i>
    <i class="fa fa-home fa-5x"></i>
    <i class="fa fa-th fa-5x"></i>-->

    <div class="row justify-content-center text-center ">
      <div class="col col-sm-6 justify-content-center py-3">
        <h3>Change Password</h3>
      </div>
    </div>
    <div class="row justify-content-center  row-register2">
      <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
        <form class="container container_inner" [formGroup]="changePasswordForm" (ngSubmit)="resetUserPassword()">

          <div class="form-group">
            <label for="username">Email</label>
            <span class="input-group-addon"><i class="glyphicon glyphicon-envelope"></i></span>
            <input formControlName="username"
                   [ngClass]="{'is-invalid': changePasswordForm.get('username').errors && changePasswordForm.get('username').touched}"
                   type="email" class="form-control" id="username" placeholder="E-Mail Address">
            <div class="invalid-feedback" *ngIf="changePasswordForm.get('username').touched && changePasswordForm.get('username').hasError('required')">Username is requiered</div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input formControlName="password"
                   [ngClass]="{'is-invalid': changePasswordForm.get('password').errors
                  && changePasswordForm.get('password').touched}"
                   type="password" class="form-control" id="password">
            <div class="invalid-feedback" *ngIf="changePasswordForm.get('password').hasError('required')
      && changePasswordForm.get('password').touched">Password is required</div>
          </div>

          <div class="form-group">
            <label for="newpassword">New Password</label>
            <input formControlName="newpassword"
                   [ngClass]="{'is-invalid': changePasswordForm.get('newpassword').errors
                  && changePasswordForm.get('newpassword').touched}"
                   type="password" class="form-control" id="new-password">
            <div class="invalid-feedback" *ngIf="changePasswordForm.get('newpassword').hasError('required')
      && changePasswordForm.get('newpassword').touched">Password is required</div>
          </div>

          <div class="form-group">
            <label for="confirmnewpassword">Confirm Password</label>
            <input formControlName="confirmnewpassword"
                   [ngClass]="{'is-invalid': changePasswordForm.get('confirmnewpassword').errors
                  && changePasswordForm.get('confirmnewpassword').touched}"
                   type="password" class="form-control" id="confirm-new-password">
            <div class="invalid-feedback" *ngIf="changePasswordForm.get('confirmnewpassword').hasError('required')
      && changePasswordForm.get('confirmnewpassword').touched">Password is required</div>
          </div>


          <div class=" text-center">
            <button type="button" class="btn btn-outline-primary" [disabled]="!changePasswordForm.valid" type="submit">Change Password</button>
            <!-- <button type="button" class="btn btn-outline-success ml-3" (click)="register()">Register</button> -->
            <!--button type="submit" class="btn btn-primary">Create Account</button>-->
          </div>
          <br />
          <div class=" text-center">
            <a [routerLink]="['/login-help']"><span class="font-weight-bold">Forgot Password</span></a>
            <span > | </span>
            <a [routerLink]="['/contact-us']"><span class="font-weight-bold text-dark">Need Help?</span></a>

          </div>

          <!--<button class="btn btn-primary" type="button" [disabled]= "this.processing" >
    <span *ngIf="this.processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Processing
  </button>-->


        </form>
      </div>

    </div>
  </div>
