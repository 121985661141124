<div id="page-top">
    <div id="wrapper">
          <!-- Add Navigation -->
          <admin-panel-nav></admin-panel-nav>

        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                
                <div class="container-fluid">

                    <!-- <form action="" #aClassForm="ngForm"> -->
                        <!-- {{ aClassForm.value | json}} -->
                        <hr />
                        <!-- {{ this.uclass | json }} -->


                        <div class="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 class="text-dark mb-0">Class Settings</h3>
                            <!-- <a class="btn btn-primary btn-sm d-none d-sm-inline-block" role="button" href="#" style="margin-top: 2em;"><i class="fas fa-save fa-sm text-white-50"></i>&nbsp;Save Settings</a> -->
                        </div>
                        <div class="row">

                        <form action="" [formGroup]="classOneForm" (ngSubmit)="saveClass(0)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 1</h4>
                                    <div>
                                        <p>{{this.uclass[0].title}}</p>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="this.uclass[0].isActive" name="isActive0" type="checkbox" id="formCheck-1"><label class="form-check-label" for="formCheck-1">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[0].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <!-- <a class="btn btn-primary btn-sm d-none d-sm-inline-block" role="button" (click)="f1.onSubmit()" style="margin-top: 2em;"><i class="fas fa-save fa-sm text-white-50"></i>&nbsp;Save Settings</a> -->
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-3">
                                
                                <!-- <button class="btn btn-primary"  type="submit">Submit</button> -->
                            </div>
                        </form>

                        <form action="" [formGroup]="classTwoForm" (ngSubmit)="saveClass(1)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 2</h4>
                                    <div>
                                        <p>{{this.uclass[1].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[1].isActive" name="isActive1" type="checkbox" id="formCheck-10"><label class="form-check-label" for="formCheck-10">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[1].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <!-- <a class="btn btn-primary btn-sm d-none d-sm-inline-block" role="button" href="#" style="margin-top: 2em;"><i class="fas fa-save fa-sm text-white-50"></i>&nbsp;Save Settings</a> -->
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classThreeForm" (ngSubmit)="saveClass(2)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 3</h4>
                                    <div>
                                        <p>{{this.uclass[2].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[2].isActive" name="isActive2" type="checkbox" id="formCheck-9"><label class="form-check-label" for="formCheck-9">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[2].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classFourForm" (ngSubmit)="saveClass(3)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 4</h4>
                                    <div>
                                        <p>{{this.uclass[3].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[3].isActive" name="isActive3" type="checkbox" id="formCheck-8"><label class="form-check-label" for="formCheck-8">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[3].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>

                        <form action="" [formGroup]="classFiveForm" (ngSubmit)="saveClass(4)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 5</h4>
                                    <div>
                                        <p>{{this.uclass[4].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[4].isActive" name="isActive4" type="checkbox" id="formCheck-7"><label class="form-check-label" for="formCheck-7">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[4].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classSixForm" (ngSubmit)="saveClass(5)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 6</h4>
                                    <div>
                                        <p>{{this.uclass[5].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[5].isActive" name="isActive5" type="checkbox" id="formCheck-6"><label class="form-check-label" for="formCheck-6">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[5].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classSevenForm" (ngSubmit)="saveClass(6)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 7</h4>
                                    <div>
                                        <p>{{this.uclass[6].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[6].isActive" name="isActive6" type="checkbox" id="formCheck-5"><label class="form-check-label" for="formCheck-5">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[6].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classEightForm" (ngSubmit)="saveClass(7)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 8</h4>
                                    <div>
                                        <p>{{this.uclass[7].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[7].isActive" name="isActive7" type="checkbox" id="formCheck-4"><label class="form-check-label" for="formCheck-4">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[7].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classNineForm" (ngSubmit)="saveClass(8)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 9</h4>
                                    <div>
                                        <p>{{this.uclass[8].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[8].isActive" name="isActive8" type="checkbox" id="formCheck-3"><label class="form-check-label" for="formCheck-3">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[8].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>
                                        
                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form action="" [formGroup]="classTenForm" (ngSubmit)="saveClass(9)">
                            <div class="col-md-6 col-xl-3 mb-4">
                                <div class="card shadow border-start-primary py-2">
                                    <h4>Class 10</h4>
                                    <div>
                                        <p>{{this.uclass[9].title}}</p>
                                    </div>
                                    <div class="form-check"><input class="form-check-input" [(ngModel)]="this.uclass[9].isActive" name="isActive9" type="checkbox" id="formCheck-2"><label class="form-check-label" for="formCheck-2">Active</label></div>
                                    <div class="card-body">
                                        <div class="row align-items-center no-gutters">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Video URL</span></div>
                                                    <input type="url" value="{{this.uclass[9].videoUrl}}">
                                                <div class="text-dark fw-bold h5 mb-0"></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-video fa-2x text-gray-300"></i></div>
                                        </div>
                                        <div class="row align-items-center no-gutters d-none">
                                            <div class="col me-2">
                                                <div class="text-uppercase text-primary fw-bold text-xs mb-1"><span>Total Users</span></div>
                                                <div class="text-dark fw-bold h5 mb-0"><span>#####</span></div>
                                            </div>
                                            <div class="col-auto"><i class="fas fa-calendar fa-2x text-gray-300"></i></div>
                                        </div>

                                        <div class="my-3">
                                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </div>
                    


                    



                </div>
            </div>
        </div><a class="border rounded d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</div>