import { Component, OnInit, ViewChild } from '@angular/core';
import { classItem } from '../models/classItem';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AlertifyService } from '../services/alertify.service';
import { ClassService } from '../services/class.service';
import { QuizService } from '../services/quiz.service';
import { ChangeDetectorRef } from '@angular/core';
import { UserClassesTaken } from '../models/user-classes-taken';
import { UserClassesService } from '../services/user-classes.service';
import { QuizQuestion } from '../models/quiz-question';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserCourseDto } from '../models/user-course-dto';
import { ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { lessThanAsyncValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  classId: number;
  class: classItem; // class 1 , class 2 ...
  quizQuestions: QuizQuestion;
  quizQ: QuizQuestion;
  previousQuestion: string;
  //scores = new Map<string, number>();
  correctAnswers = new Map(); // map javascript dictionary
  listAswers = new Map(); // aswers selected by the studers //   map javascript dictionary
  listQuizError = new Map(); // list that contains all quizzes and the state:  true or false (correct / incorrect)
  quizcompleted = false;
  videocompleted = true;
  handoutcomplete = true;
  userClassesCompleted: boolean[] = [false, false, false, false, false, false, false, false, false, false];
  lockout: boolean = false;

  quizError = false; //check if there is an error in the quiz
  userClasses: UserClassesTaken; // update quiz completed
  closeResult: string; // temp modalpopup
  show = false;
  //private fragment: string;
  private scrollExecuted: boolean = false;
  pageErrorsText: string = "";


  userCourseDto: UserCourseDto; // to check if the course is completed


  @ViewChild('content', { static: true }) modalCourse: any;
  //@ViewChild('content') myModal: any;
  
  content: any;

  constructor(private fb: FormBuilder, private router: Router,
    public authService: AuthService, private alertify: AlertifyService,
    private actRoute: ActivatedRoute, public classService: ClassService,
    public quizService: QuizService, private cdref: ChangeDetectorRef,
    public userClassesService: UserClassesService, private modalService: NgbModal, private dialogRef : MatDialog) { }
    

  ngOnInit() {
    this.classId = parseInt(this.actRoute.snapshot.params['id'], 10); // must convert to numeric
    // this.classId = this.actRoute.snapshot.params['id']; // must convert to numeric

    //lock out users who need to finish previous classes
    //this.UserClassesCompleted();

    this.getQuiz(this.classId); //should be different
    // console.log(this.classId);
    // setTimeout(() => { console.log('Quiz'); }, 5000);
    this.listQuizError.set(1, false);  //have to intilize values 
    this.listQuizError.set(2, false);
    //this.open(this.modalCourse);
  }

  ngAfterViewChecked() {
   // this.listQuizError.detectChanges();
    this.cdref.detectChanges(); // avoid error : ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'undefined'
                                // on: *ngIf="checkErrorQuestion(quiz.quizId)"
                                
    if (!this.scrollExecuted) {
      setTimeout(() => { document.getElementById('top').scrollIntoView(); }, 100);
      this.scrollExecuted = true;
    }

  }

  ngAfterViewInit(): void {
    document.getElementById('top').scrollIntoView();
  }
  /*
  scroll(sectionId) {
    let element = document.getElementById(sectionId);
    
      if(element) {
        element.scrollIntoView(); // scroll to a particular element
      }
     }
     */

  // create a list with correct answers 
  addCorrectAnswer(quizId: number , correctAnswer: string ) {
    // correct answer for the quizz
    this.correctAnswers.set(quizId, correctAnswer);
    //this.listQuizError.set(quizId, false); // create a list of errors (state) for each quiz

    for (let [key, value] of this.correctAnswers) {
      // console.log(key + ' = ' + value)
    }

    //console.log(this.correctAnswers.size);
  }

  getClass(id: number) {
    this.classService.getClassById(id).subscribe((classValue: classItem) => {
      this.class = classValue; // class 1, 2..
      //console.log(this.class);
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //set default country
      //get Url from dataBase
    }
    );
  }

  getQuiz(id: number) {
    this.quizService.getQuiz(id).subscribe((quizQs: QuizQuestion) => {
      this.quizQuestions = quizQs; // class 1, 2..

      // console.log(this.quizQuestions);
     // console.log(this.quizQuestions);
    }
    // , error => {
    //   this.alertify.error(error);
    // }
    // , () => { // third part , complete
    //   //set default country
    //   //get Url from dataBase
    // }
    );
    //this.quizQuestions = quizQuestions;
  }

  // when iteration quizzes, do not write the same question  
  updateQuestion(question : string) {
    this.previousQuestion = question; 
  }

  addStudentAnswer(quizQuestionId: number, studentAnswer) {
    // e.target.value
    this.listAswers.set(quizQuestionId, studentAnswer.target.value);
  }

  validateQuiz() {

   
    //let progress: number;
    //this.userClassesService.returnProgress(progress, this.authService.returnCurrentUser().id);

    //return;

    // clean the variables from the last validation 
    this.quizcompleted = false;
    this.quizError = false;
    for (let [keyQuiz, value] of this.correctAnswers) {
      //console.log('question: ' + keyQuiz);

      //console.log('key stude: ' + this.listAswers.get(keyQuiz));
      //console.log('value:' + value);

      // to compare to object they must be converted to String 
      if (String(this.listAswers.get(keyQuiz)) != String(value)) {
        this.quizError = true;
        this.listQuizError.set(keyQuiz, true);
      }
      else {
        this.listQuizError.set(keyQuiz, false);
      }
     // this.cdref.detectChanges();
      //console.log('this.listQuizError.get(' + keyQuiz + ') : ' + this.listQuizError.get(keyQuiz));
    }

    if (this.quizError == false) {
      this.quizcompleted = true;  
      this.pageErrorsText = "You must wait 7 days after completing this quiz before accessing the next class." +
                            // "You will be able to access the next activity at: " + nextDate.toLocaleString();";      
      this.updateQuizCompleted();

    }
  }

  openDialog() {
    this.dialogRef.open(PopupComponent, {
      data : {
        pageErrorsText : this.pageErrorsText
    }
  });
}

  checkErrorQuestion(quizQuestionId: number) {
   // console.log('checking : ' + quizQuestionId + ' - ' + this.listQuizError.get(quizQuestionId));

    return this.listQuizError.get(quizQuestionId);
  }

  //This is what routes the user to the next video
  continue() {
    // go to the next video
    let nextVideo = 0;
    nextVideo = +this.classId + +1;
    //console.log('nextVideo' + nextVideo);
    if (nextVideo != 11) {
      this.router.navigate(['/classes']);
      // this.router.navigate(['/video', nextVideo]);
    }
  }

  updateQuizCompleted() {

    this.userClasses = {
      classId: this.classId,
      userId: this.authService.returnCurrentUser().id,
      videoLenght: 0, // does not have effect, we are updating: isQuizCompleted 
      videoWatched: 0, // does not have effect, we are updating: isQuizCompleted  
      isQuizCompleted: true,
      isVideoCompleted: false, // does not have effect, we are updating: isQuizCompleted 
      isHandoutCompleted: false, // does not have effect, we are updating: isQuizCompleted    
      whenClassStarted: null,
      whenClassCompleted: null,
      isAvailable: false,
    };
    //console.log(this.userClasses);
    this.userClassesService.UpdateUserClassesTakenVideoCompleted(this.userClasses).subscribe(next => {

    }, error => {

    }, () => {
        // check if student finishes all classes
        this.userCourseDto = {
          userId: this.authService.returnCurrentUser().id,
          courseId: this.authService.returnCourseId(),
          isCourseComplete: this.authService.getCourseCompleted() 
        }
        this.userClassesService.CheckAllClassesComplete(this.userCourseDto).subscribe((courseCompleted: number) => {
           // set percentage
          let percentageWatched = courseCompleted * 100 / 30;
          localStorage.setItem('percentage', percentageWatched.toString());
          //this.userClassesService.returnProgress(percentageWatched, this.authService.returnCurrentUser().id);
          if (courseCompleted == 30) {
            // showmodal
            // this.open(this.modalCourse);
            // check if there is not other update before
            if (this.authService.getCourseCompleted() == "false") {
              this.alertify.success('You have completed the course Intercessory Prayer !');
              this.authService.setCourseCompleted();
            }
          }
        }
        );
    }
    );
  }


  //Function to lock users out of classes where all previous classes have not been completed
  UserClassesCompleted() {
    this.userCourseDto = {
      userId: this.authService.returnCurrentUser().id,
      courseId: this.authService.returnCourseId(),
      isCourseComplete: this.authService.getCourseCompleted() 
    }

    this.userClassesService.GetAllClassesComplete(this.userCourseDto).subscribe((activeList: boolean[]) => {
      this.userClassesCompleted = activeList;
      
      /*if (this.userClassesCompleted != null) {

        for (let i = 0; i < 10; i++) {
              console.log(i + " activeList: " + activeList[i].toString());
            if(activeList[i].toString() == 'true') {
              console.log(i + " true: " + this.userClassesCompleted[i]);
              this.userClassesCompleted[i] = true;
            } else {
              console.log(i + " false: " + this.userClassesCompleted[i]);
              this.userClassesCompleted[i] = false;
            } 
        }
  
          if(this.userClassesCompleted[0]) {
            console.log("Yes");
          } else {
            console.log("NO");
          }
      
      }*/

      if (this.classId != 1) {
        for (let i = 0; i < (this.classId - 1); i++) {
          if(this.userClassesCompleted[i] == false) {
            this.lockout = true;
          }
        }
      }

      // console.log("SesameSeed Class num: " +this.classId);
      if (this.lockout) {
        this.router.navigate(['/classes']);
      };

      //this.alertify.success('Users have been added to the class.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        //this.router.navigate(['/classes']);
    }
    );

  }

  open(content) {
    //const modalRef = this.modalService.open(QuizComponent);
   // modalRef.componentInstance.name = 'World';

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  linkToHint(question: string) {
    //alert(this.classId + " <-> " + Number(question.charAt(0)));

    return '../../assets/handout/Session_' + this.classId + '_Handout_Online.pdf';

  }
  

}


