import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertifyService } from '../services/alertify.service';
import { Router } from '@angular/router';
import { Contact } from '../models/contact';
import { ContactUsService } from '../services/contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  contact: Contact;


  constructor(private fb: FormBuilder, private router: Router,
    private alertify: AlertifyService, private contactUsService: ContactUsService) { }

  ngOnInit() {
    this.createContactForm();
  }

  createContactForm() {
    this.contactForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phone: [''],
      comment : ['', Validators.required]
    });
  }

  contactUs() {

    this.contact = Object.assign({}, this.contactForm.value);

    this.contactUsService.sendEmailContactUs(this.contact).subscribe(() => {
      //console.log('contact form sent ');
    }, error => {
      //console.log('Error: ' + error);
      this.alertify.error(error);
    }, () => {
        this.router.navigate(['/contact-us-sent']); 
    }
    );
  }

}
