import { Component, OnInit } from '@angular/core';
import { AlertifyService } from '../services/alertify.service';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserConfirmEmail } from '../models/user-confirm-email';
import { UserDto } from '../models/user-dto';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {

  emailConfirm: string;
  token: string;
  userConfirm: UserConfirmEmail;

  constructor(private alertify: AlertifyService, private actRoute: ActivatedRoute,
     private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.emailConfirm = this.actRoute.snapshot.params['email'];
    this.token = this.actRoute.snapshot.params['token'];
    //console.log(this.emailConfirm);

    this.userConfirm

    this.userConfirm = {
      email : this.emailConfirm,
      token : this.token
    };
    // confirm, then login
    this.authService.confirmEmail(this.userConfirm).subscribe(() => {
      //console.log('OK confirmEmail: ');
    }, error => {
      //console.log('Errror: ' + error);
      this.alertify.error(error);
    }, () => { // third part , login
       // this.loginConfirmEmail();
    }
    );

  }

  loginConfirmEmail() {

    this.authService.loginConfirmEmail(this.userConfirm).subscribe(next => {
      // nothing
    }, error => {
      this.alertify.error(error); //  'Failed to login');
    }, () => {

        // login from confirmation, do nothing
      //const userDto: UserDto = JSON.parse(localStorage.getItem('user'));

      //if (!this.authService.currentUser['mustChangePassword']) {
      //  // everithign OK  -> should go to Menu 
      //  this.router.navigate(['/welcome']);
      //}
      //else {
      //  this.alertify.error("User must change password");
      //  this.router.navigate(['/chage-password', this.authService.returnCurrentUser().id]);
      //}

    }
    );
  }

}
