import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,  ActivatedRoute  } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';
import { User } from '../../models/user';
import { UserReports } from '../../models/user-classes-reports'
import { UserClassesService } from '../../services/user-classes.service';
import { UserCourseDto } from '../../models/user-course-dto';
import { UserService } from '../../services/user.service';
import { CustomValidators } from '../../shared/custom.validators';
import { UserClassesTaken } from '../../models/user-classes-taken';
import { UserInfoReport } from '../../services/userInfoReportService';
import * as XLSX from 'xlsx';
import { Pagination } from '../../models/pagination';
import { UserParams } from '../../models/UserParams';
//import { ConsoleReporter } from 'jasmine';

declare function getToday(): any;

@Component({
  selector: 'admin-panel-users',
  templateUrl: './admin-panel-users.component.html',
  styleUrls: ['./admin-panel-users.component.css',
               '../../../assets/bootstrap/css/bootstrap.min.css'
             ]
})
export class AdminUsersComponent implements OnInit {
  model: any = {};
  addToClassForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed
  email: string;
  token: string;
  usermap = new Map();//new Map<number, boolean>();

  //users: User[];
  users: UserReports[];
  user1: UserReports = null;
  users2: UserReports[];

  allusers: UserReports[];
  pagination: Pagination;
  pageNumber: number;
  pageSize: number;

  userParams: UserParams = new UserParams();;
  user: User;

  itemsPerPage: Array<number> = [2, 5, 10, 25];
  // titles:Array<Object> = [
  //   {name: "Title*"},
  //   {name: "MD"},
  //   {name: "RN"},
  //   {name: "Mr"},
  //   {name: "Ms"}
  // ];


  usersClasses: UserClassesTaken[];
  emailForm: FormGroup;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  fileName= 'ExcelSheet.xlsx';
  classCompleted: string = "no";
  usersClassesListds = new Map();



  constructor(private fb: FormBuilder, private router: Router, private userService: UserService, 
        public authService: AuthService, private alertify: AlertifyService,private actRoute: ActivatedRoute,
        private userClassesService: UserClassesService) { //this.pageNumber = 1; this.pageSize = 5;
       
        }

  ngOnInit() {
    this.loadUsers();
    // this.createEmailForm();
    //this.getAllUserCourses();

    //this.processUsers();
  }

  exportexcel(): void
  {
    /* pass here the table id */
    let element = document.getElementById('dataTable');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
 
  }


  loadUsers() 
  {
    let ppp = this.authService.getCD();
    // console.log("pre-load");
    this.userService.getUserList(ppp, this.userParams).subscribe(response => {

        this.users = response.result;
        this.pagination = response.pagination;  //response is paginated results

        // console.log(this.users);
        for (var cc of this.users) {
        //   console.log(cc.email);
        //   console.log(cc);
        this.usermap.set(cc.email, cc);
        //   let i = 0;
        //   // for (var dd of cc.userClasses) {
        //   //   this.usersClassesListds.set(dd.userId + dd.classId, i);
        //   //   i++;
        //   // }
        }

    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }
    );
  }

  filterUsers() {

    if (this.userParams.username == "" || this.userParams.username == null) {
      this.userParams.username = null;
    } else {
      this.userParams.username = this.userParams.username.trim().toLowerCase();
    }

    if (this.userParams.firstname == "" || this.userParams.firstname == null) {
      this.userParams.firstname = null;
    } else {
      this.userParams.firstname = this.userParams.firstname.trim().toLowerCase();
    }

    if (this.userParams.lastname == "" || this.userParams.lastname == null) {
      this.userParams.lastname = null;
    } else {
      this.userParams.lastname = this.userParams.lastname.trim().toLowerCase();
    }

    // console.log("Username: " + this.userParams.username);
    // console.log("Firstname: " + this.userParams.firstname);
    // console.log("Lastname: " + this.userParams.lastname);

    this.loadUsers();
  }

  pageChanged(event: any) {
    this.userParams.pageNumber = event.page;
    this.loadUsers();
  }

  itemsPerPageChanged() {
    this.loadUsers();
  }

  sortaz() {
    this.users.sort((a, b) => a.lastname.localeCompare(b.lastname));
  }

  sortnum() {
    this.users.sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  gotouser() {

  }

  getuser() {
    let uemail = this.emailForm.value.email.toLowerCase().trim();
    // console.log("*************************")
    // console.log(uemail);
    let result = this.getuserbyemail(uemail);
    if (result !== "-1")
    {this.user1 = result;}
    //console.log(usr);
  }

  getuserbyemail(uemail: string) {
    if(this.usermap.has(uemail))
    {
        return this.usermap.get(uemail);
    }
    //return this.usermap.get(uemail);
    return "-1";
  }

  getUCInfo(users: UserReports, classnum: number) 
  {
    // console.log("UIC Info");
    // classnum++;
    let img_path: string = "assets/img/cancel.png";
    this.classCompleted = "no";
    // console.log(users);

    switch(classnum) { 
      case 1: { 
        if(users.class1) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        }
         break; 
      } case 2: { 
        if(users.class2) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        }
         break; 
      } case 3: { 
        if(users.class3) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        }
         break; 
      } case 4: { 
        if(users.class4) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        }
         break; 
      } case 5: { 
        if(users.class5) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        } 
         break; 
      } case 6: { 
        if(users.class6) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        } 
         break; 
      } case 7: { 
        if(users.class7) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        } 
         break; 
      } case 8: { 
        if(users.class8) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        }
         break; 
      } case 9: { 
        if(users.class9) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        } 
         break; 
      } case 10: { 
        if(users.class10) {
          this.classCompleted = "yes";
          img_path = "assets/img/check.png";
        } 
         break; 
      }
    } 
    // console.log('user: ' + users.email);
    // console.log('class num: ' + classnum);
    // console.log('image link: ' + img_path);
    return img_path;
  }



  //Assigns values to the form
  createAddToClassForm() {
    this.addToClassForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]],
      listofnames: ['', [Validators.required]]
    });
  }

  addToClass() {
    this.model = Object.assign({}, this.addToClassForm.value);
  
    this.authService.addToClass(this.model).subscribe(next => {
      this.alertify.success('Users have been added to the class.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

}
