<nav class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
    <div class="container-fluid d-flex flex-column p-0"><a class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" href="#">
            <div class="sidebar-brand-icon rotate-n-15"><i class="fa fa-cogs"></i></div>
            <div class="sidebar-brand-text mx-3"><img src="assets/img/logo1.png" height="40em"></div>
        </a>
        <hr class="sidebar-divider my-0">
        <ul class="navbar-nav text-light larger-font" id="accordionSidebar ">
            <li class="nav-item"><a class="nav-link active" href="admin-dashboard"><i class="fa fa-tachometer fa-2x"></i><span style="margin-left:.3em;">Dashboard</span></a></li>
            <li class="nav-item"><a class="nav-link" href="admin-dashboard"><i class="fa fa-home"></i><span style="margin-left:.15em;">Home</span></a></li>
            <li class="nav-item"><a class="nav-link" href="admin-classes"><i class="fa fa-life-ring"></i><span style="margin-left:.15em;">Classes</span></a></li>
            <li class="nav-item"><a class="nav-link" href="admin-users"><i class="fa fa-user-circle"></i><span style="margin-left:.15em;">Users</span></a></li>
            <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-cog"></i><span style="margin-left:.15em;">Settings</span ></a></li>
        </ul>
        <div class="text-center d-none d-md-inline">
            <!-- <button class="btn rounded-circle border-0" id="sidebarToggle" type="button"></button> -->
        </div>
    </div>
</nav>