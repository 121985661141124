import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import {  ChangePasswordComponent } from './change-password/change-password.component';
import { RegisterComponent } from './register/register.component';
import { ClassesComponent } from './classes/classes-list/classes.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { VideoComponent } from './video-class/video.component';
import { MessageSignupComponent } from './message-signup/message-signup.component';
import { QuizComponent } from './quiz/quiz.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginHelpComponent } from './login-help/login-help.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactUsSentComponent } from './contact-us-sent/contact-us-sent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginAuthGuard } from "./LoginAuthGuard"
import { AdminAuthGuard } from "./AdminAuthGuard"
import { AddToClassComponent } from './add-to-class/add-to-class.component';
import { AdminComponent } from './admin-dashboard/admin-panel/admin-panel.component';
import { AdminClassesComponent } from './admin-dashboard/admin-panel-classes/admin-panel-classes.component'; 
import { AdminUsersComponent } from './admin-dashboard/admin-panel-users/admin-panel-users.component'; 
import { AdminUserComponent } from './admin-dashboard/admin-panel-user/admin-panel-user.component'; 
import { ConstructionComponent } from './construction-page/construction.component';
//import { UserCreateComponent } from './users/user-create/user-create.component';
//import { UserListComponent } from './users/user-list/user-list.component';
//import { AuthGuard } from './guards/auth.guard';
//import { ChangePasswordComponent } from './change-password/change-password.component';
//import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-help', component: LoginHelpComponent },
  { path: 'reset-password/:email/:token', component: ResetPasswordComponent }, // it comes from email (reset password)
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contact-us-sent', component: ContactUsSentComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'confirmemail/:email/:token', component: ConfirmEmailComponent },
  { path: 'construction', component: ConstructionComponent },
  { path: 'classes', component: ClassesComponent, canActivate:[LoginAuthGuard]},
  { path: 'welcome', component: WelcomeComponent, canActivate:[LoginAuthGuard]},
  { path: 'video/:id', component: VideoComponent, canActivate:[LoginAuthGuard]},
  { path: 'message-signup', component: MessageSignupComponent },
  { path: 'quiz/:id', component: QuizComponent, canActivate:[LoginAuthGuard]},

  { path: 'quiz/:id', component: QuizComponent, canActivate:[LoginAuthGuard] },
  { path: 'learn-more', component: LearnMoreComponent },
  { path: 'add-to-class', component: AddToClassComponent }, 
  { path: 'dashboard', component: DashboardComponent, canActivate:[LoginAuthGuard] },
  
  { path: 'admin-dashboard', component: AdminComponent , canActivate:[AdminAuthGuard], data: {role: 'ROLE_ADMIN'} },
  { path: 'admin-classes', component: AdminClassesComponent , canActivate:[AdminAuthGuard], data: {role: 'ROLE_ADMIN'} },
  { path: 'admin-users', component: AdminUsersComponent , canActivate:[AdminAuthGuard], data: {role: 'ROLE_ADMIN'} },
  { path: 'admin-user/:id', component: AdminUserComponent , canActivate:[AdminAuthGuard], data: {role: 'ROLE_ADMIN'} },
  
  
  //{ path: 'user-create', component: UserCreateComponent, canActivate: [AuthGuard] },
  //{ path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
  //{ path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard] },
 
  //{ path: 'chage-password/:id', component: ChangePasswordComponent },
  //{ path: '**', redirectTo: '', pathMatch: 'full' },
];
