<div id="page-top">
    <div id="wrapper">
      <!-- Add Navigation -->
      <admin-panel-nav></admin-panel-nav>
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <div class="container-fluid">


                    
                        <!-- {{ usersForm.value | json}} -->
                        <hr />
                        <!-- {{ this.uclass | json }} -->
                        <div class="row row-cols-2">
                            <div class="col">
                                <h3 class="text-dark mb-4">Intercessory Users</h3>
                            </div>
                            <div class="col">
                                
                                            <!-- Email Form -->
                                            <div class="text-md-end dataTables_filter" id="dataTable_filter">
                                                
                                                <form #form="ngForm" (ngSubmit)="filterUsers()" autocomplete="off">
                                                    
                                                    <div class="form-group d-inline mb-2"> 
                                                        <div class="row row-cols-2">
                                                            <div class="col mb-2">
                                                                <h4 style="text-align: left;">Search for users</h4>
                                                            </div>
                                                            
                                                        <div class="col form-group mb-2">
                                                            <label class="form-label d-block" style="text-align: left;">Email: </label>
                                                            <input type="text" class="form-control ml-1 d-inline w-100" name="email" 
                                                                [(ngModel)]="userParams.username">
                                                        </div>
                                                        <div class="col form-group px-2 mb-2">
                                                            <label class="form-label d-block" style="text-align: left;">First Name: </label>
                                                            <input type="text" class="form-control ml-1 d-inline w-100" name="firstname" 
                                                                [(ngModel)]="userParams.firstname">
                                                        </div>
                                                        <div class="col form-group px-2 mb-2">
                                                            <label class="form-label d-block" style="text-align: left;">Last Name: </label>
                                                             <input type="text" class="form-control ml-1 d-inline w-100" name="lastname" 
                                                                 [(ngModel)]="userParams.lastname">
                                                         </div>
                                                        </div>

                                                    </div>  
                                                    <button class="btn btn-primary btn-sm d-inline-block mb-2 me-2" type="submit">Filter</button> 
                                                    <a class="btn btn-primary btn-sm d-none d-sm-inline-block mb-2 mr-2" role="button" (click)="exportexcel()"><i
                                                        class="fa fa-download fa-sm text-white-50"></i>&nbsp;Export to Excel</a>
                                                        
                                                </form>
                                            </div>
                            </div>
                        </div>

                        <form action="" #usersForm="ngForm">
                        <div class="card shadow">
                            <div class="card-header py-3">
                                <p class="text-primary m-0 fw-bold">User Info</p>
                            </div>
                            <div class="card-body">
                                <div class="row row-cols-2">


                                    <div class="col text-nowrap">
                                        <div id="dataTable_length" class="dataTables_length" aria-controls="dataTable">
                                            <label class="form-label">Show&nbsp;
                                               <select id="title" name="title" #title="ngModel" [(ngModel)]="userParams.pageSize" (change)="itemsPerPageChanged()" class="d-inline-block form-select form-select-sm">
                                                    <option [ngValue]="2" [selected]="true">2</option>
                                                    <option [ngValue]="5">5</option>
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="20">20</option>
                                                    <option [ngValue]="50">50</option>
                                                    <option [ngValue]="100">100</option>
                                                    <option [ngValue]="200">200</option>
                                                    <option [ngValue]="100000">All</option>
                                                </select>&nbsp;
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col"></div>

                                </div>

                                <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                    <table class="table my-0" id="dataTable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Class 1</th>
                                                <th>Class 2</th>
                                                <th>Class 3</th>
                                                <th>Class 4</th>
                                                <th>Class 5</th>
                                                <th>Class 6</th>
                                                <th>Class 7</th>
                                                <th>Class 8</th>
                                                <th>Class 9</th>
                                                <th>Class 10</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                


                                            <tr *ngFor="let item of this.users">
                                                <td><a [routerLink]="['/admin-user', item.userId]" [queryParams]="{ id: item.userId }"><img class="me-2" width="25" height="25" src="assets/img/double-chevron.png">{{item.firstName}} {{item.lastName}} {{item.userId}}</a></td>
                                                <td>{{item.email}} </td>

                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 1)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 2)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 3)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 4)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 5)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 6)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 7)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 8)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 9)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
                                                <td><img class="me-2" width="25" height="25" src="{{this.getUCInfo(item, 10)}}"><p style="visibility: hidden">{{this.classCompleted}}</p></td>
 
                                            </tr>
 
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Class 1</th>
                                                <th>Class 2</th>
                                                <th>Class 3</th>
                                                <th>Class 4</th>
                                                <th>Class 5</th>
                                                <th>Class 6</th>
                                                <th>Class 7</th>
                                                <th>Class 8</th>
                                                <th>Class 9</th>
                                                <th>Class 10</th>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 align-self-center">
                                        <p id="dataTable_info" class="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of 27</p>
                                    </div>
                                    <div class="col-md-6">
                                        <nav class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"  *ngIf="pagination">
                                            <pagination 
                                                [boundaryLinks]="true"  
                                                [totalItems]="pagination.totalItems"
                                                [itemsPerPage]="pagination.itemsPerPage" 
                                                [(ngModel)]="pagination.currentPage" 
                                                (pageChanged)="pageChanged($event)"  
                                                previousText="&lsaquo;" 
                                                nextText="&rsaquo;" 
                                                firstText="&laquo;" 
                                                lastText="&raquo;">
                                            </pagination>

                                        </nav>


                                    </div>
                                </div>


                            </div>
                        </div>
                    
                    </form>
                </div>
            
            </div>
        </div><a class="border rounded d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</div>