
<div class="container container_no_spaces">

  <div class="row justify-content-center  row-register2">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">

      <div class="container  container_inner mt-3">
        <div class="form-group ">
          <h4 class="text-blue"> Certificate of Completion</h4>
        </div>
        <br />
        <a href="javascript:void();">
          <img style="width:150px;height:45px;" src="../../assets/download1.png" alt="" (click)="downloadCertificate()">
        </a>
        <!--<a href="../../assets/download1.png" download>
      <img src="../../assets/download1.png" alt="W3Schools">
    </a>-->
      </div>

    </div>

  </div>
</div>
