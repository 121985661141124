import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  baseUrl = environment.baseUrl + 'class/';
  constructor(private http: HttpClient) { }

  getClassById(id: number) {
    let params = new HttpParams();
    params = params.append('id', id.toString());

    return this.http.get(this.baseUrl +'GetClassById', { params });
  }

  getVideoClass(videoName : string) {
    let params = new HttpParams();
    params = params.append('videoName', videoName);

    return this.http.get(this.baseUrl + 'GetVideo', { params });
  }

  getActiveClasses() {
    return this.http.get(this.baseUrl + 'GetActiveClasses');
  } 

  getAllClasses() {
    let u = this.http.get(this.baseUrl + 'GetAllClasses');
    // console.log(u);
    //return this.http.get(this.baseUrl + 'GetAllClasses');
    return u;
  } 

}
