
<div class="container container_no_spaces ">
  <div class="row justify-content-center  ">
    <div class="col mb-3">

    </div>

  </div>

  <div class="container ">
    <!-- Start Title -->
    <div class="row bg-warning mb-2">

      <div class="col-12 mb-2">

        <span class="" style="font-size: 2rem;font-weight:bold"> Class Topics</span>
        <br />
        <span style="font-size: 1rem; font-weight:bold">Welcome to our Intercessory Prayer Training hosted by the Prayer Ministry at Living Word Christian Center under the leadership of Drs. Bill & Veronica Winston.  It is a great joy to know that you are taking the time to invest in your spiritual growth and enhance your prayer life.</span>

      </div>

    </div>
    <!-- End Title -->

    <!-- Start Instructions -->
    <div class="row bg-warning mb-2 pt-1">
      <div class="col-12 col-lg-6">
        <p class=""><b>Directions for Intercessory prayer training:</b></p>
        <p><i>*Classes should be completed in order starting at Session 1.<br />
        *Complete each class and wait 3 days before moving on to the next one.</i></p>

        <ul>
          <li>
            <p><b>Step 1:</b> Watch the video</p>
          </li>
          <li>
          <p><b>Step 2:</b> Study the prayer training ebook</p>
          </li>
          <li>
            <p><b>Step 3:</b> Take the quiz</p>
          </li>
        </ul>

        <ul>
          <li><a href="https://fliphtml5.com/qubm/rlzm" target="_blank" style="text-transform: uppercase;">The Prayer Training Ebook</a></li>
          <li><a href="https://fliphtml5.com/qubm/bklt" target="_blank" style="text-transform: uppercase;">Frequently Asked Questions</a></li>
        </ul>

      </div>
        <div class="col-12 col-lg-6">
          <p><b>What's included:</b></p>

        <ul>
          <li>
            <p>A class teaching of each class topic.</p>
          </li>
          <li>
          <p>An Ebook chapter for each class topic.</p>
          </li>
          <li>
            <p>Quizes for each topic</p>
          </li>
        </ul>
            <p><i>Successful completion of each quiz is required to complete the 10 week training program.</i></p>
      </div>
    </div>
    <!-- End Instructions -->

    <!-- Start Class Cards -->
    <div *ngIf="userClassesList && userClassesTakenList" class="row bg-light">

      <!-- Load all of the classes -->
        <div *ngFor="let userClass of userClassesList" class="col-12 col-md-6 col-xl-4 col-xxl-3">
          <div *ngIf="userClassesTakenList[userClass.id*1 -1] as userClassesTaken;">
            <app-class-card [userClass]="userClass" [userClassesTaken]="userClassesTaken" [userClassesTakenList]="userClassesTakenList"></app-class-card>
          </div>
            
        </div>

      
    </div>
    <!-- End Class Cards -->

    </div>

   

</div>


<script>

  function updateCheckHandout(idHandout) {
    alert(document.getElementById("checkHandout" + idHandout));
    // console.log(document.getElementById("checkHandout" + idHandout));
    document.getElementById("checkHandout" + idHandout).src = "../assets/checkmark-check.png";
  }

</script>