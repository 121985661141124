<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
<div class="container container_no_spaces ">
  <div class="row justify-content-center  ">
    <div class="col mb-3">

    </div>

  </div>

  <div class="row justify-content-center  ">
    <div class="col col-sm-11  col-md-11  col-lg-11 justify-content-center">

      <div class="container container_inner2 mb-4  ">

        <div class="row   row-register2 mb-4  " valing="top">
          <div class="col col-sm-12  col-md-12  col-lg-12  visible-md-none .d-md-none " valign="top">
            <img class="visible-lg-block .d-md-none crop" style="padding-left: 10px;" src="../assets/Intercessory-Prayer-Training.jpg" alt="" />
          </div>

        </div>

        <div class="row justify-content-center text-center ">
          <div class="col col-sm-12 justify-content-center py-3">
            <h2>Intercessory Prayer Training </h2>
          </div>
        </div>
        <div class="row   row-register2 mb-4  " valing="top">
          <div class="col col-sm-12  col-md-12  col-lg-12  " valign="top">
            <!--<span>
    The Word of God says in Jeremiah 29:11-14 (New Living Translation),
  </span>
  <span class="italicfont">"For I know the plans I have for you,"</span>   says the Lord. <span class="italicfont">"They are plans for good and not for disaster, to give you a future and a hope. In those days when you pray, I will listen. If you look for me in earnest, you will find me when you seek me. I will be found by you," </span> says the Lord.
  <span class="italicfont">"I will end your captivity and restore your fortunes. I will gather you out of the nations where I sent you and bring you home again to your own land." </span>

  <br />
  <br />
  <span> The good plans that God has intended for you in these prayer classes will only be realized as you put into practice the things you are about to learn. Although many of you come from various backgrounds and are all at different levels, there are significant nuggets in these classes designed for the most seasoned prayer warrior as well as for the new Believer.</span>
  <br />
  <span>
    The treasure you will uncover in this training will provide the tools you need to pray and get the ear of the King. This training will give you the "why" and the "how to" of having a relationship with God through prayer. When you (a) know how to go to God in time of need; (b) know how to exercise your authority; and (c) know how to be a student of the Word, then God can use you in the various areas of ministry that He would have you serve in. We will endeavor to give you the principles and basics of prayer through teachings, demonstrations, videos, and your participation in prayer groups. It's entirely up to you how far you progress and develop in your personal prayer life, once you receive it - then it's up to you to apply it in your life.
  </span>-->
            <span>
              Specifically, in this ten-week training, we plan to provide the resources that will help each student develop their personal prayer life on such topics as:
            </span>
            <ul>
              <li>
                How to pray God's Word
              </li>
              <li>
                How to pray audibly and flow in a prayer group
              </li>
              <li>
                How to listen for the Holy Spirit and follow His promptings
              </li>
              <li>
                The various kinds of prayer, such as the Prayer of Petition, Prayer of Dedication, Prayer of <br/>Praise and Worship, and much more!
               </li>
            </ul>            
            <span>
              <p>Our service to you is accomplished if we can effectively communicate to you that God hears and responds to the prayers of the righteous, and you are the righteous! You do not need anyone else to go to God for you. You have the authority, the right, and the privilege to go to Him with His Word and receive the desired results. So come each week with a determination in your mind and heart that you will move to a deeper level in your personal prayer life by the Spirit of God. Expect God to meet you right where you are.<br /></p>
            </span>
            <span>
              <p>The treasure you will uncover in this training will provide the tools you need to pray and get the ear of the King. This training will give you the “why” and the “how-to” of having a relationship with God through prayer. When you (a) know how to go to God in time of need; (b) know how to exercise your authority, and; (c) know how to be a student of the Word, then God can use you in the various areas of ministry that He would have you serve in. We will endeavor to give you the principles and basics of prayer through teachings, demonstrations, videos, and your participation in prayer groups. It’s entirely up to you how far you progress and develop in your personal prayer life. Once you receive it, then it’s up to you to apply it in your life.<br /><br /></p>
            </span>
            <!-- Deleted on 6/4/2021 -->
            <!-- <span>
              <p>Specifically, in this 10-week training, we plan to provide the resources that will help each student develop their own personal prayer life on such topics as:<br /></p>
              <p>Our service to you is accomplished if we can effectively communicate to you that God hears and responds to the prayers of the righteous (and you are the righteous!) . . .you do not need anyone else to go to God for you. You have the authority, the right and the privilege to go to Him with His Word and receive the desired results. So come each week with a determination in your mind and heart that by the Spirit of God you are going to move to a deeper level in your personal prayer life. Expect God to meet you right where you are.<br /></p>

            </span> -->

          </div>

        </div>



      </div>

    </div>
  </div>

</div>
