import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  baseUrl = environment.baseUrl + 'quiz/';
  constructor(private http: HttpClient) { }

  getQuiz(classId: number) {
    let params = new HttpParams();
    params = params.append('classId', classId.toString());

    return this.http.get(this.baseUrl + '', { params });
  }


}
