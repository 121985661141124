import { Injectable } from '@angular/core';
import { UserClassesService } from '../services/user-classes.service';
import { UserClassesTaken } from '../models/user-classes-taken';
import { AuthService } from './auth.service';
import { FormBuilder } from '@angular/forms';
import { AlertifyService } from './alertify.service';

@Injectable({
    providedIn: 'root'
}) 


export class CheckCompletion {
    //listOfUserClasses = new Map(); //map datastructure for searching in user classes
    listClassId = new Map();
    listClassesComplete = new Map(); // list of all classes completed //   map javascript dictionary
    listQuizComplete = new Map(); // list  of all quizzes completed
    listLengthVideo = new Map(); // list  of all videos length
    listLengthWatchedVideo = new Map(); // list  of watched video by the student
    listHandoutComplete = new Map(); // list  of all Handout completed
    userClassesTakenLst: UserClassesTaken[]; // list of classes completed by the student
    userClasses: UserClassesTaken; // to update the handout
    uCTList: UserClassesTaken[]; // list of classes completed by the student
    allClasses: Array<number> = [1,2,3,4,5,6,7,8,9,10];

    constructor(private fb: FormBuilder,
        public authService: AuthService, private alertify: AlertifyService,
        private userClassesService: UserClassesService) { }
    
      ngOnInit() {
    //     //this.height = 0;
    //     this.videoUrl = 'https://lwccmediafilesstorage.blob.core.windows.net/mediafilescontainer/Intercessory_Prayer_Class_1.mp4?sv=2018-03-28&sr=b&sig=4UT1vqE5oywS9RBeqTX%2FaLCe7nA%2FU7%2BcJq%2F6aPWo4LA%3D&se=2020-12-17T03%3A10%3A19Z&sp=r';
       
        //setTimeout((console.log("cc");), 2600);
    
        this.GetAllClassesTakenByUser(this.authService.returnCurrentUser().id);
      }

      getStatus(userId: number) {
          //console.log(this.userClassesTakenLst);
          //this.GetAllClassesTakenByUser(userId);
          return this.iterateThruCompleted();
      }

      getStatusFromList(lst: any[], userId: number) {

            this.listClassId = lst[0];
            this.listClassesComplete = lst[1];
            this.listQuizComplete = lst[2];
            this.listLengthVideo = lst[3];
            this.listLengthWatchedVideo = lst[4];
            this.listHandoutComplete = lst[5];
            return this.iterateThruCompleted();
      }

      iterateThruCompleted() {
          let message:[number, string, string];
          let completed = true;
         // let message = "";
        //itterate thru all of the classes by class number 1 thru 10
        for (var i of this.allClasses) {
            //console.log(this.listClassId.has(i));
          //check to see if the video has been watched, 
          //console.log(i);
          if (this.listClassId.has(i)) {
            //console.log(this.listClassId.has(i));
            if (this.listClassesComplete.get(i) == 0) {
                completed = false;
                //if not break with message: video # needs to be watched next
                message = [i, "Video", "video " + i + " should be watched next."];
                return message;
            }
            //check to see if the handout has been downloaded
            if (this.listHandoutComplete.get(i) == 0) {
                completed = false;
                //if not break with message: handout # needs to be reviewed
                message = [i, "Handout", "Handout " + i + " should be completed next."];
                return message;
            }
            //check to see if the quiz has been completed
            if (this.listQuizComplete.get(i) == 0) {
                completed = false;
                //if not break with message: Quiz # needs to be completed
                message = [i, "Quiz", "Quiz " + i + " should be completed next."];
                return message;
            }
          } else {
              completed = false;
              //if not break with message: video # needs to be watched next
              message = [i, "Video", "video " + i + " should be watched next."];
              return message;
          }
        }

          //if all material has been completed
          if (completed) {
              //if not break with message: All material has been completed
              message = [100, "None", "All material has been completed"];
          }
          return message;
      
    }


    // printStatus() {
    //     console.log('this works');
    // }

GetAllClassesTakenByUser(userId: number) {
    this.userClassesService.GetAllClassesTakenByUser(userId).subscribe((uCTList: UserClassesTaken[]) => {
      this.uCTList = uCTList;
      //console.log("User id: " + userId);
      //console.log(this.uCTList);
      // now we need to iterate the list to update the list of classes completed
      for (var classTaken of uCTList) {
        this.listClassId.set(classTaken.classId, classTaken.classId);
        this.listClassesComplete.set(classTaken.classId, classTaken.isVideoCompleted);
        this.listQuizComplete.set(classTaken.classId, classTaken.isQuizCompleted);
        this.listLengthVideo.set(classTaken.classId, classTaken.videoLenght);
        this.listLengthWatchedVideo.set(classTaken.classId, classTaken.videoWatched);
        this.listHandoutComplete.set(classTaken.classId, classTaken.isHandoutCompleted);
      }
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //do nothing
    }
    );
  }
  

}