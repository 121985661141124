import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,  ActivatedRoute  } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { UserDto } from '../../models/user-dto';
import { UserClassesService } from '../../services/user-classes.service';
import { UserCourseDto } from '../../models/user-course-dto';
import { UserService } from '../../services/user.service';
import { CustomValidators } from '../../shared/custom.validators';
//import { resourceLimits } from 'worker_threads';
import { CourseService } from '../../services/course.service';
import { Course } from '../../models/course';
import { UserNewReport } from '../../models/user-new-report';
import { ReportService } from '../../services/report.service';
import { Observable } from 'rxjs';

declare function getToday(): any;

@Component({
  selector: 'admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css',
               '../../../assets/bootstrap/css/bootstrap.min.css'
             ]
})
export class AdminComponent implements OnInit {
  model: any = {};
  addToClassForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed
  email: string;
  token: string;
  isChecked: string = "";
  totalUsers: number = 0;
  newUsers: number = 0;
  graduatedUsers: number = 0;
  InactiveUsers: 0;
  tasks: number = 0;
  pendingRequests: number = 0;


  topics = ['Angular', 'React', 'Vue'];
  title = 'tdf';
  course: Course;
  maintenanceMode: boolean;
  usersAll: UserNewReport[];
  usersNew: UserNewReport[];
  usersCompleted: UserNewReport[];
  usersInactive: UserNewReport[];


  constructor(private fb: FormBuilder, private router: Router,
    private userService: UserService, public authService: AuthService, private alertify: AlertifyService,private actRoute: ActivatedRoute,
    private userClassesService: UserClassesService, private courseService: CourseService, private reportService: ReportService) { }

    /*
  ngOnInit() {
    this.createLoginForm();

    this.processing = true;
    if (this.authService.loggedIn()) {
      this.router.navigate(['/home']); 
    }

  }
  */
  ngOnInit() {
    //this.getMaintenanceMode();
    this.checkIfActive();
    this.getUserStats();
  }


  //Assigns values to the form
  createAddToClassForm() {
    this.addToClassForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]],
      listofnames: ['', [Validators.required]]
    });
  }

  /*
  resetUserPassword() {
    this.model = Object.assign({}, this.changePasswordForm.value);

    this.authService.changePassword(this.model).subscribe(next => {
      this.alertify.success('Your password had been changed successfully.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }
  */
  getMaintenanceMode() {
      this.isChecked = "";

      //this.courseService.checkIfActive()
  }

  getUserStats() {
    let ppp = this.authService.getCD();
      this.reportService.getStats(ppp).subscribe((result: any[]) => {
        console.log("User Stats: ");
        console.log(result);
        this.totalUsers = result[0];
        this.newUsers = result[1];
        this.graduatedUsers = result[2];
        this.InactiveUsers = result[3];
      }, error => {
        //this.alertify.error(error); //  'Failed to loginsss');
      }
      );
  }

  setMaintenanceMode() {
      console.log("MaintenanceMode");
      let ppp = this.authService.getCD();
      this.courseService.setMaintenanceMode(ppp).subscribe((result: boolean) => {
          console.log(result);
          this.maintenanceMode = result;
          // console.log( " activeList: " + activeList[0].toString());
      // if(activeList[0].toString() == 'false') {
      //     this.maintenanceMode = true;
      //     console.log("activeList[0].toString");
      //     //this.router.navigate(['/construction']);
      //   }   
      }, error => {
        //this.alertify.error(error); //  'Failed to loginsss');
      }
      );
  }

  checkIfActive() {
    console.log("Is this active?");
    this.courseService.isCourseActive().subscribe((activeList: boolean[]) => {

        console.log( " activeList: " + activeList[0].toString());
    if(activeList[0].toString() == 'false') {
        this.maintenanceMode = true;
        console.log("activeList[0].toString");
        //this.router.navigate(['/construction']);
      }   
    }, error => {
      //this.alertify.error(error); //  'Failed to loginsss');
    }
    );
  }



  getCourseList() {
    console.log('getCountryList');
    this.courseService.getCourseList().subscribe((courses: Course[]) => {
      this.course = courses[0];
      console.log(courses);//activeCourse
      console.log(this.course);
      console.log(this.course.IsActive);
      
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
        //set default country
        //this.registerForm.controls['countryId'].setValue('US');
    }
    );
  }


  addToClass() {
    this.model = Object.assign({}, this.addToClassForm.value);
  
    this.authService.addToClass(this.model).subscribe(next => {
      this.alertify.success('Users have been added to the class.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

  exportExcel(exportMethod: (secret: string, days: number) => Observable<Blob>, days: number, logMessage: string) {
    let secret = this.authService.getCD();
    console.log(logMessage);

    exportMethod(secret, days).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv'; // You can set the file name dynamically if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.alertify.success('File has been exported');
    }, error => {
      this.alertify.error('Failed to export file');
    });
}

exportAllExcel(days: number) {
    this.exportExcel(this.reportService.exportAllExcel.bind(this.reportService), days, "Export All");
}

exportCompleteExcel() {
    this.exportExcel(this.reportService.exportCompleteExcel.bind(this.reportService), -1, "Export Complete 1");
}

exportInactiveExcel() {
    this.exportExcel(this.reportService.exportInactiveExcel.bind(this.reportService), 30, "Export Inactive 1");
}

  /*exportAllExcel(days: number) {
    let secret = this.authService.getCD();
    console.log("Export All");

    this.reportService.exportAllExcel(secret, days).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv'; // You can set the file name dynamically if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.alertify.success('File has been exported');
    }, error => {
      this.alertify.error('Failed to export file');
    });
  }
  exportCompleteExcel() {
    let days = 0;
    let secret = this.authService.getCD();
    console.log("Export Complete 1");

    this.reportService.exportCompleteExcel(secret, days).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv'; // You can set the file name dynamically if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.alertify.success('File has been exported');
    }, error => {
      this.alertify.error('Failed to export file');
    });
  }
  exportInactiveExcel() {
    let days = 30;
    let secret = this.authService.getCD();
    console.log("Export Inactive 1");

    this.reportService.exportInactiveExcel(secret, days).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv'; // You can set the file name dynamically if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.alertify.success('File has been exported');
    }, error => {
      this.alertify.error('Failed to export file');
    });
  }*/


  // exportNewExcel() {
  //   let ppp = this.authService.getCD();
  //   console.log("Export New");
  //   // this.userService.exportNewExcel(ppp).subscribe(next => {
  //   this.reportService.exportNewExcel(ppp).subscribe((result: UserNewReport[]) => {
  //     this.alertify.success('file has been exported');
  //     this.usersNew = result;
  //     console.log(this.usersNew);
  //   }, error => {
  //     this.alertify.error(error);
  //   }, () => {
  //     //action on error
  //     // console.log("failed!");
  //   });
  // }
  /*exportCompleteExcel() {
    let ppp = this.authService.getCD();
    console.log("Export Completed");
    // this.userService.exportCompleteExcel(ppp).subscribe(next => {
    this.reportService.exportCompleteExcel(ppp).subscribe((result: UserNewReport[]) => {
      this.alertify.success('file has been exported');
      this.usersCompleted = result;
      console.log(this.usersCompleted);
    }, error => {
      this.alertify.error(error);
    }, () => {
      //action on error
      // console.log("failed!");
    });
  }
  exportInactiveExcel() {
    let ppp = this.authService.getCD();
    console.log("Export Inactive");
    // this.userService.exportInactiveExcel(ppp).subscribe(next => {
    this.reportService.exportInactiveExcel(ppp).subscribe((result: UserNewReport[]) => {
      this.alertify.success('file has been exported');
      this.usersInactive = result;
      console.log(this.usersInactive);
    }, error => {
      this.alertify.error(error);
    }, () => {
      //action on error
      // console.log("failed!");
    });
  }*/

}
