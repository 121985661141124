import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PopupComponent } from './popup/popup.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'Intercessory';
  firstName: string;

  constructor(private dialogRef : MatDialog) {}

openDialog() {
  this.dialogRef.open(PopupComponent, {
    data : {
      name : 'Samuel'
  }
});
}


  getUrl() {
    return "url('/assets/sky8.jpg')";
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    //...
}
}
