import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }

  confirm(title: string, message: string, okCallback: () => any, noOkCallback: () => any) {
    alertify.confirm(title, message, function (e) {
      if (e) {
        okCallback();
      } else { noOkCallback(); }
    }
    );

    alertify.confirm(title, message, function () { okCallback(); }
      , function () { noOkCallback(); });
  }

  confirmVoid(title: string, message: string, okCallback: () => void, noOkCallback: () => void) {
    alertify.confirm(title, message, function (e) {
      if (e) {
        return "Ok";
      } else { return "Cancel"; }
    }
    );

    alertify.confirm(title, message, function () { okCallback(); }
      , function () { noOkCallback(); });
  }

  confirmString(title: string, message: string, okCallback: () => any, noOkCallback: () => any): string {
    let resp: string;
    alertify.confirm(title, message, function (e) {
      if (e) {
        resp = "Ok";
      } else { resp = "Cancel"; }
    }
    );
    return resp;
  }

  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }

}
