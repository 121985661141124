import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CourseService } from '../services/course.service';
import { Course } from '../models/course';
// import { consoleTestResultHandler } from 'tslint/lib/test';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  //isCourseActive: string;
  courses: Course[];

  constructor(public authService: AuthService, private router: Router, public courseService: CourseService ) { }

  ngOnInit() {
    // console.log("11111");
    this.checkIfActive();
    //console.log('cthis.currentUser: ' + this.authService.currentUser);
  }

  getNameUser() {
    //return this.authService.currentUser['email']
    let currentUser = this.authService.returnCurrentUser();
    return currentUser.firstName + ' ' +  currentUser.lastName;
  }

  continueToClasses() {
    this.router.navigate(['/classes']);
  }


  checkIfActive() {
    this.courseService.isCourseActive().subscribe((activeList: boolean[]) => {
    if(activeList[0].toString() != 'true') {
        this.router.navigate(['/construction']);
      }   
    }, error => {
      //this.alertify.error(error); //  'Failed to loginsss');
    }
    );
  }


}

//            var user1 = JSON.stringify(user.user);
//var user2 = JSON.parse(user1);

