import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,  ActivatedRoute  } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { UserDto } from '../../models/user-dto';
import { Country } from '../../models/country';
import { UserClassesService } from '../../services/user-classes.service';
import { CountryService } from '../../services/country.service';
import { UserCourseDto } from '../../models/user-course-dto';
import { UserService } from '../../services/user.service';
import { CustomValidators } from '../../shared/custom.validators';
import { UserOne } from '../../models/userone';
import { UserClassesTaken } from '../../models/user-classes-taken';
import { classItem } from '../../models/classItem';
import { ClassService } from '../../services/class.service';
// import { ConsoleReporter } from 'jasmine';
//import 'rxjs/operator/filter';

declare function getToday(): any;

@Component({
  selector: 'admin-panel-user',
  templateUrl: './admin-panel-user.component.html',
  styleUrls: ['./admin-panel-user.component.css',
               '../../../assets/bootstrap/css/bootstrap.min.css'
             ]
})
export class AdminUserComponent implements OnInit {
  model: any = {};
  addToClassForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed
  email: string;
  token: string;
  id: string;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  // users: UserReports[];
  user: UserOne;
  userProfForm: FormGroup;
  userPassForm: FormGroup;
  countries: Country[];

  userClassesList: classItem[];  //list of all classes
  userClassesTakenList: UserClassesTaken[]; // list of classes completed by the student
  //userClassesList: [Class][UserClassesTaken];  
  // userClassesListds = new Map(); 
  fileName= 'ExcelSheet.xlsx';
  classCompleted: string = "no";
  // startedDate: Date;
  // completedDate: Date;
  // availableDate: Date;
  editMode: boolean = false;
  isAvailable: boolean[] = [false, false, true, true, false, true, false, true, false,true];
  userClassesListds = new Map();

  

  constructor(private fb: FormBuilder, private router: Router,private route: ActivatedRoute,
    private userService: UserService, public authService: AuthService, private countryService: CountryService, private alertify: AlertifyService,private actRoute: ActivatedRoute,
    private userClassesService: UserClassesService, public classService: ClassService,) { }

  ngOnInit() {
    // let userId = this.authService.returnCurrentUser().id
    let ppp = this.authService.getCD();

    this.id = this.actRoute.snapshot.params['id'];
    // console.log("id: " + this.id);

    this.getUser(Number(this.id), ppp);
    
    // this.getCountryList();

    this.GetAllClassesTakenByUser(Number(this.id));
    // const d = new Date("2022-03-25");
    
  }

  GetAllClassesTakenByUser(userId: number) {
    this.userClassesService.GetAllClassesTakenByUser(userId).subscribe((userClassesTakenList: UserClassesTaken[]) => {
      this.userClassesTakenList = userClassesTakenList;
      
      //get the number of classes that user has started
      let length = this.userClassesTakenList.length;

      //sanitize the isAvailable field for null values
      this.userClassesTakenList.forEach(uct => {
        this.userClassesListds.set(uct.classId, uct);
      });

      //check if the user has not started any classes or if they have started all 10
      if (length == null || length < 10) {

        let uid = this.authService.returnCurrentUser().id

        //increase to the first null class
        length++;
        
        //loop through all of the classesn and make sure the array is 10 elements long
        for (let i = 0 ; i < 10; i++) {
          let uct: UserClassesTaken;
          if (this.userClassesListds.has(i + 1)) {
            uct = this.userClassesListds.get(i + 1);
          } else {
            // initialize the first class after the last started one
            uct = {
              classId: i + 1,
              userId: Number(this.id), //this.authService.returnCurrentUser().id,
              videoLenght: 0,
              videoWatched: 0,
              isQuizCompleted: false,
              isVideoCompleted: false,
              isHandoutCompleted : false,
              whenClassStarted: null,
              whenClassCompleted: null,
              isAvailable: false,
            };
          }

          this.userClassesTakenList[i] = uct;

        }
        
        // console.log(this.userClassesTakenList);
      }
      
      // console.log(this.userClassesTakenList);


    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //do nothing
    }
    );
  }

  saveProgressChanges() {
    console.log("Save Progress");
    // console.log(this.userClassesTakenList);
    // console.log(this.isAvailable);
    this.userClassesService.UpdateAllUserClassesTaken(this.userClassesTakenList).subscribe(next => {
      console.log("Classes Updated")
    }, error => {
      this.alertify.error(error);
    }, () => {

    });
    
    this.editMode = false;
  }

  GetAllClasses() {
    this.classService.getAllClasses().subscribe((userClasses: classItem[]) => {
      this.userClassesList = userClasses;
      //this.userClassesListds.set("classes", this.userClassesList);

    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //do nothing
    }
    );
  }

  getStartedDate(uct: UserClassesTaken) {
    if (uct.whenClassStarted != null) {
      let startingDate = new Date(uct.whenClassStarted);
      return startingDate.toDateString(); //.toDateString() .toLocaleString()
    } else {
      return "Not Started";
    }
  }

  getCompletedDate(uct: UserClassesTaken) {
    let defaultDate = new Date("0001-01-01T00:00:00");
    if (uct.whenClassCompleted != null && uct.whenClassCompleted != defaultDate) {
      let completedDate = new Date(uct.whenClassCompleted);
      return completedDate.toDateString(); //.toDateString() .toLocaleString()
    } else {
      return "Not Completed";
    }
  }

  getAvailableDate(uct: UserClassesTaken) {
    //Only process available date if not in the first class
    if(uct.classId != 1) {
      let defaultDate = new Date("0001-01-01T00:00:00");
      //find out if the previous class was completed
      let previousClass = this.userClassesTakenList[uct.classId - 2];
      // console.log("previous Class");
      // console.log(previousClass);
      if (previousClass.whenClassCompleted != null) {
        let previousDate = new Date(previousClass.whenClassCompleted)
        if (previousDate.getTime() != defaultDate.getTime()) {
          // console.log("AvailableDates");
          // console.log(defaultDate);
          // console.log(previousDate);
          let availableDate = new Date(previousClass.whenClassCompleted);
          // console.log("AvailableDates1");
          // console.log(availableDate);
          availableDate.setDate(availableDate.getDate() + 7); 
          // console.log(availableDate);
          return availableDate.toDateString();
          //return "now";
        } else {
            return "TBD"
          }
      } else {
        return "TBD"
      }
    //return the date if the class was started
    // } else if (uct.whenClassStarted != null) {
    //   let availableDate = new Date(uct.whenClassStarted);
    //   return availableDate.toDateString();
    //return string available if not started
    } else {
      return "Available"
    }
  }

  CreateUserProfForm(user: UserOne) {
    this.userProfForm = this.fb.group({
      id: [this.user?.id, Validators.required],
      username: [this.user?.email, Validators.required],
      email: [this.user?.email, [Validators.required, Validators.pattern(this.emailPattern)]],
      firstname: [this.user?.firstName, [Validators.required]],
      lastname: [this.user?.lastName, [Validators.required]],
      city: [this.user?.city, [Validators.required]],
      countryId: ['US', Validators.required],
    });
  }

  CreateUserPassForm(user: UserOne) {
    this.userPassForm = this.fb.group({
      email1: [this.user?.email, [Validators.required]],
      pass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
    });
  }

  saveUser() {
    let ppp = this.authService.getCD();
    // console.log("ClickUser");

    if (this.userProfForm.valid) {
      // move the vslues from this.registerForm.value to {}
      this.user = (<any>Object).assign({}, this.userProfForm.value);
      // console.log("*********************");
      // console.log(this.user);
      this.user.userId = this.userPassForm.value.id;
      this.userService.updateUser(this.user, ppp).subscribe(() => {
        this.alertify.success('Update successful');
      }, error => {
        console.log(error);
        this.alertify.error(error);
        //this.alertify.success('Registration successful');
      }, () => { // third part , complete, we nee to redirect the user once they have register
          //this.router.navigate(['message-signup']);
      });
      
    }
  }

  savePass() {
    let ppp = this.authService.getCD();
    // console.log("ClickPass");
    console.log(this.userPassForm.valid)
    if (this.userPassForm.valid) {
      // console.log("got here2!")
      // move the vslues from this.registerForm.value to {}
      //this.user = (<any>Object).assign({}, this.userPassForm.value);
      this.user.password = this.userPassForm.value.pass;
      
      // console.log("*********************");
      // console.log(this.user);

      this.userService.changePass(this.user, ppp).subscribe(() => {
        this.alertify.success('Password Change Successful');
        // this.router.navigate(['message-signup']);
      }, error => {
        console.log(error);
        this.alertify.error(error);
        //this.alertify.success('Registration successful');
      }, () => { // third part , complete, we nee to redirect the user once they have register
          //this.router.navigate(['message-signup']);
      });


    }
  }


  getUser(id: number, ppp: string) {
    this.userService.getUserById(id, ppp).subscribe((useridValue: UserOne) => {
      this.user = useridValue; // class 1, 2..
      // console.log(this.user);
      this.CreateUserProfForm(useridValue);
      this.CreateUserPassForm(useridValue);
      this.getCountryList();
      //console.log(this.class);
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //set default country
      //get Url from dataBase
    }
    );
  }


  addToClass() {
    this.model = Object.assign({}, this.addToClassForm.value);
  
    this.authService.addToClass(this.model).subscribe(next => {
      this.alertify.success('Users have been added to the class.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

  getCountryList() {
    console.log('getCountryList'); 
    this.countryService.getCountriesList().subscribe((countries: Country[]) => {
      this.countries = countries;
      // console.log(countries);
      
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
        //set default country
        this.userProfForm.controls['countryId'].setValue('US');
    }
    );
  }

}
