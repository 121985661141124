import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router,  ActivatedRoute  } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { UserDto } from '../../models/user-dto';
import { ClassService } from '../../services/class.service';
import { UserCourseDto } from '../../models/user-course-dto';
import { UserService } from '../../services/user.service';
import { CustomValidators } from '../../shared/custom.validators';
import { classItem } from '../../models/classItem';


declare function getToday(): any;

@Component({
  selector: 'admin-panel-classes',
  templateUrl: './admin-panel-classes.component.html',
  styleUrls: ['./admin-panel-classes.component.css',
               '../../../assets/bootstrap/css/bootstrap.min.css'
             ]
})
export class AdminClassesComponent implements OnInit {
  model: any = {};
  addToClassForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed
  email: string;
  token: string;
  uclass: classItem[];
  classOneForm: FormGroup;
  classTwoForm: FormGroup;
  classThreeForm: FormGroup;
  classFourForm: FormGroup;
  classFiveForm: FormGroup;
  classSixForm: FormGroup;
  classSevenForm: FormGroup;
  classEightForm: FormGroup;
  classNineForm: FormGroup;
  classTenForm: FormGroup;


  /*
  
    id: number;
  title: string;
  smallTitle: string;
  description: string;
  order: number;
  image: string;
  videoUrl: string;
  VideoLenght: number;
  
*/

  isActive: boolean[];
  //isActive2: boolean[] = [true, true, true, true, true, true, true, true, true, true];
  //isActive2: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  constructor(private fb: FormBuilder, private router: Router,
    private userService: UserService, public authService: AuthService, private alertify: AlertifyService,private actRoute: ActivatedRoute,
    private classService: ClassService) { }

    /*
  ngOnInit() {
    this.createLoginForm();

    this.processing = true;
    if (this.authService.loggedIn()) {
      this.router.navigate(['/home']); 
    }

  }
  */
  ngOnInit() {
    this.getAllClasses(); 
    this.CreateForms();
  }



  //Assigns values to the form
  CreateForms() {
    this.classOneForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });

    this.classTwoForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });

    this.classThreeForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classFourForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classFiveForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classSixForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classSevenForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classEightForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classNineForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
    this.classTenForm = this.fb.group({
      // username: ['', Validators.required],
      // password: ['', [Validators.required]],
      // listofnames: ['', [Validators.required]]
    });
  }

  saveClass(classnum: number) {
      console.log("class saved!")
  }


  /*
  updateQuizCompleted() {

    this.userClasses = {
      classId: this.classId,
      userId: this.authService.returnCurrentUser().id,
      videoLenght: 0, // does not have effect, we are updating: isVideoCompleted  
      videoWatched: 0, // does not have effect, we are updating: isVideoCompleted  
      isQuizCompleted: true,
      isVideoCompleted: true, // does not have effect, we are updating: isVideoCompleted  
      isHandoutCompleted: true, // does not have effect, we are updating: isVideoCompleted    
      whenClassStarted: null,
      whenClassCompleted: null
    };
    //console.log(this.userClasses);
    this.userClassesService.UpdateUserClassesTakenVideoCompleted(this.userClasses).subscribe(next => {

    }, error => {

    }, () => {
        // check if student finishes all classes
        this.userCourseDto = {
          userId: this.authService.returnCurrentUser().id,
          courseId: this.authService.returnCourseId(),
          isCourseComplete: this.authService.getCourseCompleted() 
        }
        this.userClassesService.CheckAllClassesComplete(this.userCourseDto).subscribe((courseCompleted: number) => {
           // set percentage
          let percentageWatched = courseCompleted * 100 / 30;
          localStorage.setItem('percentage', percentageWatched.toString());
          //this.userClassesService.returnProgress(percentageWatched, this.authService.returnCurrentUser().id);
          if (courseCompleted == 30) {
            // showmodal
            // this.open(this.modalCourse);
            // check if there is not other update before
            if (this.authService.getCourseCompleted() == "false") {
              this.alertify.success('You have completed the course Intercessory Prayer !');
              this.authService.setCourseCompleted();
            }
          }
        }
        );
    }
    );
  }
*/







  getAllClasses() 
  {
    this.classService.getAllClasses().subscribe((uclass: classItem[]) => {
        this.uclass = uclass;
        console.log(this.uclass);
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }
    );
  }


  /*
  resetUserPassword() {
    this.model = Object.assign({}, this.changePasswordForm.value);

    this.authService.changePassword(this.model).subscribe(next => {
      this.alertify.success('Your password had been changed successfully.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }
  */
  addToClass() {
    this.model = Object.assign({}, this.addToClassForm.value);
  
    this.authService.addToClass(this.model).subscribe(next => {
      this.alertify.success('Users have been added to the class.');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {
        this.router.navigate(['/login']);
    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

}
