<!-- cards -->
     <!-- userClassesListds -->
<div *ngIf="userClass" class="card border-secondary mb-3">

    <div class="card-body text-white" style="height: 493.3px;" >
        <h6  class="card-title text-uppercase">{{ userClass.title }}</h6>

        <img class="card-img-top" src="../../assets/classes/class{{ userClass.id }}.jpeg" alt="Card image cap">
 
        <p class="card-text truncate" style="padding-top: 1em;">
          {{userClass.description}}
        </p>
    </div>

    <div class="card-footer">
        <table style="width:100%;">

            <tr>
              <td style="width:100%" colspan="2">
                <!-- <div class="progress mb-1" style="height: 8px;">
                 <div class="progress-bar   bg-info" role="progressbar" [ngStyle]="{width: getPercentageVideoWatched() + '%'}" aria-valuemin="0" aria-valuemax="100"><span style="font-size:x-small"> {{getPercentageVideoWatchedText()}} </span></div>
                </div> -->
              </td>
            </tr>

            <tr>
              <td style="width:80%; padding-bottom:.25em;">
                  <!-- <a class=" " [routerLink]="['/video/1']" [queryParams]="{ id: 1 }"> -->
                  <div (click)="get_video()" class="user-button" style="cursor: pointer;">
                    <!-- <img style="width:29px; height:29px; " class="card-img-top2" src="../assets/play1_blue.png" alt=" image cap" (click)="watchVideo(1)"> -->
                    <img style="width:29px; height:29px; " class="card-img-top2" src="../assets/play1_blue.png" alt=" image cap">
                    <!-- </a> -->
                    <p class="card-text ml-1 font-weight-bold" style=" display: inline-block; padding-left: .25em;">Watch Class {{userClass.id}} </p>
                  </div>

              </td>
              <td style="width:20%;" align="left">
                <div *ngIf="this.userClassesTaken">
                  <ng-container *ngIf="this.userClassesTaken.isVideoCompleted; then videocompleted; else videouncompleted"></ng-container>
                  <ng-template #videocompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-check.png" alt=" image cap">
                  </ng-template>
                  <ng-template #videouncompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-uncheck.png" alt=" image cap">
                  </ng-template>
                  
                </div>
              </td>
            </tr>

            <tr>
              <td style="width:80%; padding-bottom:.25em;">
                <!-- <a class=" " [routerLink]="['/video/1']" [queryParams]="{ id: 1 }"> -->
                  <div (click)="get_handout()" class="user-button " style="cursor: pointer;">
                    <!-- <a class=" " href="../../assets/handout/Session_1_Handout_Online.pdf"    (click)="getHandout(1)"  target="_blank"> -->
                    <img   style="width:29px; height:29px; " class="card-img-top2" src="../../assets/pdf_icon.png" alt=" image cap">
                    <!-- </a> -->
                    <p class="card-text ml-1 font-weight-bold" style=" display: inline-block; padding-left: .25em;">Study class {{userClass.id}} E-book </p>
                  </div>
              </td>
              <td style="width:20%; " align="left">

                <div *ngIf="userClassesTaken">
                  <ng-container *ngIf="this.userClassesTaken.isHandoutCompleted; then handoutcompleted; else handoutuncompleted"></ng-container>
                  <ng-template #handoutcompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-check.png" alt=" image cap">
                  </ng-template>
                  <ng-template #handoutuncompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-uncheck.png" alt=" image cap">
                  </ng-template>
                </div>

                <!-- <img  id="checkHandout1" style="width:20px; height:20px" align="left" class="card-img-top2"  [src]="getCheckHandout()"  alt=" image cap"> -->

              </td>
            </tr>

            <tr>
              <td style="width:80%; padding-bottom:.25em;">

                <!-- <img style="width:20px; height:20px" align="left" class="card-img-top2" [src]="getCheckQuiz()" alt=" image cap"> -->
                <div (click)="get_quiz()" class="user-button" style="cursor: pointer;">
                  <img style="width:29px; height:29px; " class="card-img-top2" src="../../assets/quiz1.png" alt=" image cap">
                  <!-- </a> -->
                  <p class="card-text ml-1 font-weight-bold" style=" display: inline-block; padding-left: .25em;">Quiz {{userClass.id}}</p>
                </div>

              </td>
              <td style="width:20%; " align="left">

                <div *ngIf="userClassesTaken">
                  <ng-container *ngIf="this.userClassesTaken.isQuizCompleted; then quizcompleted; else quizuncompleted"></ng-container>
                  <ng-template #quizcompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-check.png" alt=" image cap">
                  </ng-template>
                  <ng-template #quizuncompleted >
                    <img style="width:20px; height:20px" align="left" class="card-img-top2" src="../../assets/checkmark-uncheck.png" alt=" image cap">
                  </ng-template>
                </div>

                
              </td>
            </tr>

        </table>
       
    </div>



</div>

