import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import { UserOne } from '../models/userone';
import { PaginatedResult } from '../models/pagination';
import { UserInfoReport } from '../services/userInfoReportService';
import { UserReports } from '../models/user-classes-reports';
import { UserParams } from '../models/UserParams';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.baseUrl + 'user/';
  
  // paginatedResult: PaginatedResult<UserReports[]> = new PaginatedResult<UserReports[]>();
  constructor(private http: HttpClient) { }

  resetPassword(email: string, password: string) {
    // console.log("UserService-- RESET USER PASS");
    return this.http.post(this.baseUrl + 'Reset', { "email": email, "password": password });
  }

  changeUserPassword(model: any) {
    // console.log("UserService-- CHANGE USER PASS");
    return this.http.post(this.baseUrl + 'changepassword', model);
  }

  getUserList(ppp: string, userParams: UserParams) {  //page?: number, itemsPerPage?: number) {
    // console.log("UserService-- GetUserLIst");
    // let params = new HttpParams();
    let params = this.getPaginationHeaders(userParams.pageNumber, userParams.pageSize);
    if (userParams.username != null) {
      params = params.append('userName', userParams.username.toString());
    }
    if (userParams.firstname != null) {
      params = params.append('firstName', userParams.firstname.toString());
    }
    if (userParams.lastname != null) {
      params = params.append('lastName', userParams.lastname.toString());
    }
    
    //params = params.append('userId', ppp.toString());

    // if (page !== null && itemsPerPage !== null) {
    //   params = params.append('pageNumber', page.toString());
    //   params = params.append('pageSize', itemsPerPage.toString());
    // }

    // return this.getPaginatedResult(ppp, params);
    return this.getPaginatedResult<User[]>(this.baseUrl, ppp, params);

    /*
    let u = this.http.get(this.baseUrl + 'getAll/' + ppp);
    // console.log(u);
    //return this.http.get(this.baseUrl );
    return u;
    */
  }

  private getPaginatedResult<T>(url, ppp: string, params) {
    const paginatedResult: PaginatedResult<UserReports[]> = new PaginatedResult<UserReports[]>();
    return this.http.get<UserReports[]>(this.baseUrl + 'getAllc/' + ppp, { observe: 'response', params }).pipe(
      map(response => {
        paginatedResult.result = response.body; //members array in response.body
        if (response.headers.get('Pagination') !== null) { //!==
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  private getPaginationHeaders(pageNumber: number, pageSize: number) {
    let params = new HttpParams();

      params = params.append('pageNumber', pageNumber.toString());
      params = params.append('pageSize', pageSize.toString());

      return params;
  }

  getUserById(id: number, ppp: string) {
    // console.log("UserService-- GetUserById");
    let params = new HttpParams();
    params = params.append('id', id.toString());
    // console.log("ppp");

    return this.http.get(this.baseUrl +'GetUserById/' + ppp, { params });
  }

  getUserInfoList(ppp: string) {
    // console.log("UserService-- GetUserInfoList");
    let params = new HttpParams();
    params = params.append('userId', ppp.toString());

    let u = this.http.get(this.baseUrl + 'getAllInfo/' + ppp);
    // console.log(u);
    //return this.http.get(this.baseUrl );
    return u;
  }

  changePass(user: UserOne, ppp: string) {
    // console.log("got here!");
    // console.log("???????????????????????????")
    return this.http.post(this.baseUrl + 'updateUserP/' + ppp, user);
  }

  updateUser(user: UserOne, ppp: string) {
    // console.log("UserService-- UpdateUser");
    return this.http.post(this.baseUrl + 'updateUser/' + ppp + "/" + user.id, user);
  }



}
