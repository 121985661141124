import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertifyService } from '../services/alertify.service';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { classItem } from '../models/classItem';
import { ClassService } from '../services/class.service';
import { Video } from '../models/video';
import { UserClassesService } from '../services/user-classes.service';
import { UserClassesTaken } from '../models/user-classes-taken';
import { UserDto } from '../models/user-dto';
import { UserCourseDto } from '../models/user-course-dto';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videoId: number;
  classId: number;
  videoUrl = '';
  class: classItem; // class 1 , class 2 ...
  video: Video; // hold the return JSON from controller
  userClasses: UserClassesTaken; // update videoCompleted
  countToUpdateVideoWatched: number; //
  userCourseDto: UserCourseDto; // to check if the course is completed

  // tests for Safari
  // declare FunctionsetCurrentTimeSafari2: any;
  // setCurrentTimeSafari: Function;


  @ViewChild('videoClass', { static: true }) videoClass: ElementRef;


  constructor(private fb: FormBuilder, private router: Router,
    public authService: AuthService, private alertify: AlertifyService,
    private actRoute: ActivatedRoute, public classService: ClassService,
    public userClassesService: UserClassesService ) { }

  ngOnInit() {

    // we are asumming that the classId and VideoId are corresponding: Class1 = Video1
    this.videoId = this.actRoute.snapshot.params['id'];
    this.getClass(this.videoId);
    //console.log(this.videoId);
    // console.log('IDDD' + this.authService.returnCurrentUser().id);
   // this.videoUrl = 'https://lwccmediafilesstorage.blob.core.windows.net/mediafilescontainer/Intercessory_Prayer_Class_1.mp4?sv=2018-03-28&sr=b&sig=mrena0git80DJLif9Zbn6F8JzwocpYAayeXcOj0tFJM%3D&se=2021-01-12T23%3A52%3A06Z&sp=r';
    this.countToUpdateVideoWatched = 0;

    // Test Safari 
   //this.setCurrentTimeSafari();
  }

  getClass(id: number) {
    this.classService.getClassById(id).subscribe((classValue: classItem) => {
      this.class = classValue; // class 1, 2..
      //console.log(this.class);
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //set default country

        //get Url from dataBase
        this.getVideoClass(this.class.videoUrl);
        this.getVideoWatched(this.authService.returnCurrentUser().id, this.class.id);
    }
    );
  }

  getVideoWatched(userId: number, classId: number) {
    this.userClassesService.getVideoWatched(userId, classId).subscribe((userClassesTaken: UserClassesTaken) => {
      // console.log(userClassesTaken);
      // update watched
      if (userClassesTaken != null) {

        let isSafari =  navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;

        console.log("isSafari" + isSafari);
        if (!isSafari) {
          this.videoClass.nativeElement.currentTime = userClassesTaken.videoWatched;
        }
        else {
          // safari so we have to use javascript
          //let videoObject = (document.getElementById("videoClass" ) as HTMLImageElement);
          //videoObject.currentTime = userClassesTaken.videoWatched;

          //document.getElementById('videoClass').currentTime = 33232
        }
      }

    }, error => {
      console.log('Errror: ' + error.errors);
      this.alertify.error(error);
    }, () => { // third part , complete
      //set default country
    }
    );
  }

  getVideoClass(videoName: string) {
    this.classService.getVideoClass(videoName).subscribe((video: Video) => {
      this.videoUrl = video.videoUrl; // class 1, 2..
     // console.log(this.videoUrl);
     // this.videoUrl =  'https://lwccmediafilesstorage.blob.core.windows.net/mediafilescontainer/Intercessory_Prayer_Class_1.mp4?sv=2018-03-28&sr=b&sig=mrena0git80DJLif9Zbn6F8JzwocpYAayeXcOj0tFJM%3D&se=2021-01-12T23%3A52%3A06Z&sp=r';

      // load video on control
      //
      this.videoClass.nativeElement.src = this.videoUrl;
     // this.videoClass.nativeElement.currentTime = 55.55;


      // this.videoClass.nativeElement.load();
     // this.videoClass.nativeElement.play();

    }, error => {
        console.log('Errror: ' + error);
        console.log('Errror: ' + error.errors);
        this.alertify.error(error);
    }, () => { // third part , complete
      //set default country
    }
    );
  }

  tickTimeVideo(data) {
    this.countToUpdateVideoWatched++; // must wait 15 times to update  video watched

    if (this.countToUpdateVideoWatched == 15) {
      const userDto: UserDto = JSON.parse(localStorage.getItem('user')); //get userId if we refresh the page ** pending
      // = { prop1: 'str', prop2: 3 };

      // update pe
      var duration = data.target.duration;
      var percentage;
      percentage = (data.target.currentTime * 100) / duration;
      let videoComplete = false;
      if (percentage > 96) {
        videoComplete = true;
      }	

      this.userClasses = {
        classId: this.class.id,
        userId: this.authService.returnCurrentUser().id,
        videoLenght: duration,
        videoWatched: data.target.currentTime,
        isQuizCompleted: false, // does not have effect, we are updating: isVideoCompleted   
        isVideoCompleted: videoComplete,
        isHandoutCompleted: false, // does not have effect, we are updating: isVideoCompleted    
        whenClassStarted: null,
        whenClassCompleted: null,
        isAvailable: false,
      };

      this.countToUpdateVideoWatched = 0; //reset the count to update the video watched
      // update the video watched
      this.userClassesService.UpdateUserClassesTakenVideo(this.userClasses).subscribe(next => {

      });

      //mark the video as completed
      if (videoComplete) {
        this.userClassesService.UpdateUserClassesTakenVideoCompleted(this.userClasses).subscribe(next => {
          // console.log("Video Complete!");

        }, error => {
          this.alertify.error(error);
        }, () => {
          // first check if the course was already finished
          // and the student is just watching the video again
          //update progress bar
          // check if student finishes all classes
            this.userCourseDto = {
              userId: this.authService.returnCurrentUser().id,
              courseId: this.authService.returnCourseId(),
              isCourseComplete: this.authService.getCourseCompleted() 
            }

            //removed 7-17-23
            this.userClassesService.CheckAllClassesComplete(this.userCourseDto).subscribe((courseCompleted: number) => {
            // set percentage

            //removed 7-17-23
            let percentageWatched = courseCompleted * 100 / 30;
            localStorage.setItem('percentage', percentageWatched.toString());
            
            // this.userClassesService.returnProgress(percentageWatched, this.authService.returnCurrentUser().id);
            
            //removed 7-17-23
            // if (courseCompleted == 30) {
              // showmodal -send email
              //this.open(this.modalCourse);

              //removed 7-17-23
              // if (this.authService.getCourseCompleted() == "false") {
              //   this.alertify.success('You have completed the course Intercessory Prayer !');
              //   this.authService.setCourseCompleted();
              // }
            // }
          });
        }

        );
      }
      
     
    }
  


  
  }


}