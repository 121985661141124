import { Injectable } from '@angular/core';
import { UserDto } from '../models/user-dto';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt'; // add this manually
import { HttpClient } from '@angular/common/http'; // add manually
import { map } from 'rxjs/operators'; // add manually 
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { UserConfirmEmail } from '../models/user-confirm-email';
import { EmailDto } from '../models/email-dto';
import { CourseService } from './course.service';
// import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSource = new ReplaySubject<User>(1); //buffer to store 1 version of the current user
  currentUser$ = this.currentUserSource.asObservable(); //add dollar sign to end since it is an observable

  private adminStatusSubject = new BehaviorSubject<boolean>(false);
  adminStatus$ = this.adminStatusSubject.asObservable();
  
  baseUrl = environment.baseUrl + 'Auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: UserDto;
  roleAs: any;
  ccd: any;

 

  constructor(private http: HttpClient, public courseService: CourseService ) {
    this.decodedToken = this.decodeToken();
   }


  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model)
      .pipe(
        map((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('user', JSON.stringify(user.user));
            localStorage.setItem('ccd', JSON.parse(JSON.stringify(user.ccd)));
            localStorage.setItem('isCourseCompleted', "false");
            //   var now = new Date().getTime();
            //  localStorage.setItem('setupTime', now);


            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            const isAdmin = this.decodedToken.role === 'Admin';
            console.log(`IsAdmin: ${isAdmin}`);

            this.currentUser = <UserDto>user.user;

            if (isAdmin)
              {localStorage.setItem('ROLE', 'ROLE_ADMIN');}
              else
              {localStorage.setItem('ROLE', 'user');}

               
              console.log(`IsAdmin1: ${isAdmin}`);
              console.log(this.decodedToken);
              console.log(JSON.stringify(user.user));

            this.currentUserSource.next(user);

          }
          else {
            // console.log(response);
          }
        })
      ); 
  }

  getRole(): string {
    return localStorage.getItem('ROLE');
  }
  
  async getAdminStatus(): Promise<boolean> {
    try {
      const isAdmin = this.isAdmin; // Use the isAdmin getter
      this.adminStatusSubject.next(isAdmin);
      return isAdmin;
    } catch (error) {
      console.log('Error determining admin status', error);
      this.adminStatusSubject.next(false);
      return false;
    }
  }
  
  get isAdmin(): boolean {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      console.log('No valid token found');
      return false;
    }
    const result = decodedToken.role === 'Admin';
    console.log(decodedToken.role);
    console.log("isAdmin: " + result);
    return result;
  }
  
  private decodeToken() {
    // Your logic to decode the token
    const token = localStorage.getItem('token');
    // Add your token decoding logic here
    return token ? JSON.parse(atob(token.split('.')[1])) : null;
  }

    /*getAdminStatus(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        // Simulate an async operation, e.g., HTTP request
        setTimeout(() => {
          try {
            const isAdmin = this.isAdmin; // Use the isAdmin getter
            resolve(isAdmin);
          } catch (error) {
            console.log('Error determining admin status', error);
            reject(false);
          }
        }, 1000);
      });
    }

    get isAdmin(): boolean {

      const decodedToken = this.decodeToken();
      if (!decodedToken) {
        // Handle the case where the token is not available or invalid
        console.log('No valid token found');
        return false;
      }
      let result = this.decodedToken.role === 'Admin';
      //console.log("isAdmin: " + this.decodedToken.role === 'Admin');
      console.log(this.decodedToken.role)
      console.log("isAdmin: " + result);
      return result;
    }*/


    // isAdmin(): boolean {
    //   const isAdmin = localStorage.getItem('IsAdmin');
    //   return isAdmin === '1';
    // }

  // getRole() {
  //   this.roleAs = localStorage.getItem('ROLE');
  //   return this.roleAs;
  // }

  getCD() {
    this.ccd = localStorage.getItem('ccd');
    return this.ccd;
  }

  changePassword(model: any) {
    return this.http.post(this.baseUrl + 'changepassword', model);
    /*
      .pipe(
        map((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('user', JSON.stringify(user.user));

            localStorage.setItem('isCourseCompleted', "false");
            
            //   var now = new Date().getTime();
            //  localStorage.setItem('setupTime', now);

            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            this.currentUser = <UserDto>user.user;
            //console.log('cthis.currentUser: ' + this.currentUser);  // no return 
          }
        })
      );
      */
  }

  addToClass (model: any) {
    return this.http.post(this.baseUrl + 'addtoclass', model);
  }

  register(user: User) {
    return this.http.post(this.baseUrl + 'register', user);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }


  confirmEmail(userConfirm: UserConfirmEmail) {

    return this.http.post(this.baseUrl + 'confirmation', userConfirm);
  }

  loginConfirmEmail(model: any) {
    return this.http.post(this.baseUrl + 'LoginConfirmEmail', model)
      .pipe(
        map((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('user', JSON.stringify(user.user));

            //   var now = new Date().getTime();
            //  localStorage.setItem('setupTime', now);

            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            this.currentUser = <UserDto>user.user;
              }
        })
      );
  }

  returnCurrentUser() {
    this.currentUser = <UserDto>(JSON.parse(localStorage.getItem("user")));
    return JSON.parse(localStorage.getItem("user"));
  }

  returnCourseId() {
    return environment.courseId;
  }

  // returns string : "false":"true"
  getCourseCompleted() {
    return localStorage.getItem('isCourseCompleted');
  }

  setCourseCompleted() {
     localStorage.setItem('isCourseCompleted', "true");
  }

  sendEmailResetPassword(emailDto: EmailDto) {

    return this.http.post(this.baseUrl + 'loginHelp', emailDto);
  }

 
 
}
