<div class="container container_no_spaces">
 
  
  <div class="row justify-content-center  row-register2 mt-3">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
      <form class="container container_inner" >

        <div class="row text-center">
          <div class="col">
            <img src="../../assets/thankyou.png" />
          </div>

        </div>

        <div class="row text-center">
          <div class="col">
            <br />
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <h3>Thank You!</h3>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <span>Your submission has been received.</span>
          </div>
        </div>

      </form>
    </div>

  </div>
</div>
