import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  error_message: string = "";
  error_message1: string = "";
  buttonLink: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.error_message = data.pageErrorsText;
    this.error_message1 = data.pageErrorsText1;
    this.buttonLink = data.pageErrorsLink;
}

  ngOnInit(): void {
    // console.log("testing: " + this.error_message);
    // console.log("testing1: " + this.error_message1);
    // console.log("testing1: " + this.buttonLink);
  }

}
