import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserClassesTaken } from '../../models/user-classes-taken';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { UserClassesService } from '../../services/user-classes.service';
import { UserCourseDto } from '../../models/user-course-dto';
import { classItem } from '../../models/classItem';
import { PopupComponent } from '../../popup/popup.component';
import {ClassesComponent } from '../classes-list/classes.component';
import { utils } from 'xlsx';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-class-card',
  templateUrl: './class-card.component.html',
  styleUrls: ['./class-card.component.css']
})
export class ClassCardComponent implements OnInit {
  @Input() userClassesTaken: UserClassesTaken;
  @Input() userClass: classItem;
  @Input() userClassesTakenList: UserClassesTaken[];

  //class_number: number; 
  userClasses: UserClassesTaken; // to update the handout
  userCourseDto: UserCourseDto; // to check if the course is completed
  userClassesTaken1: UserClassesTaken;
  pageErrors: any = false;
  pageErrorsText: string = "";
  pageErrorsText1: string = "";
  pageErrorsLink: string = "";


  
  


  constructor(private router: Router, private alertify: AlertifyService,
    public authService: AuthService, private userClassesService: UserClassesService,  private dialogRef : MatDialog) { }

  ngOnInit(): void {
    //this.class_number = this.userClassesTaken.classId;
    // this.userClassesTakenList.classId
    // userClassTakenList1
    // this.userClassesTaken = this.userClassesTakenList[+this.userClass.id];
    // console.log("Here: " + this.userClassesTaken.classId);
  }



  myFunction() {
    alert(this.userClass.id);
    // this.userClass.title
    // this.userClassesTaken.isQuizCompleted
    // this.userClassesTaken.isHandoutCompleted
    
  }

  // isClassComplete() {
  //   if (this.userClassesTaken.isVideoCompleted &&
  //       this.userClassesTaken.isHandoutCompleted &&
  //       this.userClassesTaken.isQuizCompleted)
  //   {return true;}
  //   else
  //   {return false};
  // }

  watchVideo( ) {
    // this.router.navigate(['/video', videoId]);
    
   }

    //  classId: 1
    // isHandoutCompleted: true
    // isQuizCompleted: false
    // isVideoCompleted: false

    findlastClass(item: UserClassesTaken) { 
        
        // if(item.whenClassCompleted != null) {
        //   this.currentClass = item.classId*1;
        // }
    }

    getRestrictions(activity: number) {

      // check if student finishes all classes, and allow all access
      if (JSON.parse(this.authService.getCourseCompleted())) {
        console.log("User has completed the course");
        console.log(this.authService.getCourseCompleted());
        return true;
      } else if (this.userClassesTakenList[this.userClass.id - 1].isAvailable){
        console.log(this.userClassesTakenList[this.userClass.id - 1].isAvailable);
        console.log("Course Override");
        return true;
      }
      
      console.log("restrictions")
      //popup modals for info
      //find last class finished
      // let currentClass: number = 0;    //class that was last worked on
      let thisClass = this.userClass.id;  //class that the user clicked on
      // console.log(thisClass);
      let nextClass = -1;
      let nextActivity = -1;
      let activityName = "";
      this.pageErrorsLink = "";
      this.pageErrorsText = "";
      this.pageErrorsText1 = "";

      let i = 0;
      // find the last class that was worked on
      //loop through all classes and break from loop when 1st class not taken is found
      // this.userClassesTakenList.forEach((uct) => {//////////
      // this.userClassesTakenList.every(uct => {.forEach

      console.log("Here");
      console.log(this.userClassesTakenList);
      this.userClassesTakenList.every(uct => {
          // console.log("Loop: " + i);
          i++;
          if (uct.isVideoCompleted == false) {// && uct.whenClassCompleted != null) {
              nextClass = uct.classId;
              nextActivity = 1;
              activityName = "Video";
              this.pageErrorsLink = "/video/" + uct.classId;
              return false;
          } else if (uct.isHandoutCompleted == false) { // && uct.whenClassCompleted != null) {
              nextClass = uct.classId;
              nextActivity = 2;
              activityName = "E-book";
              this.pageErrorsLink = "";
              return false;
          } else if (uct.isQuizCompleted == false) { // && uct.whenClassCompleted != null) {
              nextClass = uct.classId;
              nextActivity = 3;
              activityName = "Quiz";
              this.pageErrorsLink = "/quiz/" + uct.classId;
              return false;
          }
          return true;
      });

      //Print results of what next class and activity should be
      console.log("Next Class: " + nextClass);
      console.log("Next Activity: " + nextActivity);
      console.log("Activity Name: " + activityName);

      //ignore these rules if the class selected is the first class, need to check finish time of last class
      if (thisClass*1 != 1) {
          console.log("Entered rules 1");

          //find out if the user is trying to access a class or activity that is greater than what the have next
          if (thisClass > nextClass) {
            this.pageErrorsText = "the next activity to finish is Class " + nextClass + " Activity " + activityName;
            this.pageErrors = true;
            console.log(this.pageErrorsText);
            return false;
          // if this is the right class, find out if the activity is greater than what is available
          } else if (thisClass == nextClass && activity > nextActivity ) {
            this.pageErrorsText = "the next activity to finish is Class " + nextClass + " Activity " + activityName;
            this.pageErrors = true;
            console.log(this.pageErrorsText );
            return false;
          //if going to a new class, has it been 7 days since the last one was finished
          } else if (thisClass == nextClass && activity == nextActivity && activity == 1) {
              //if not class one and prior class is completed but have not waited 7 days, message = You must wait 7 days after completing class # before accessing the next
              let today = new Date();
              let nextDate = new Date(this.userClassesTakenList[nextClass - 2].whenClassCompleted);
              nextDate.setDate(nextDate.getDate() + 7);
              if (today < nextDate) {
                  this.pageErrorsText = "You must wait 7 days after completing class " + (nextClass -1) + " before accessing the next class";
                  this.pageErrorsText1 = "You will be able to access the next activity at: " + nextDate.toLocaleString();
                  this.pageErrors = true;
                  console.log(this.pageErrorsText);
                  return false;
              } 
          } else {
              return true;
          }
      //rules only for class 1
      } else {
          if (thisClass == nextClass && activity > nextActivity ) {
              this.pageErrorsText = "the next activity to finish is Class " + nextClass + " Activity " + activityName;
              this.pageErrors = true;
              console.log(this.pageErrorsText );
               return false;
          }
      }

      //Everything is ok, continue
      return true;
    }

   get_video() {
    let cont = this.getRestrictions(1);

    if (cont) {
    let rlink = "/video/" + this.userClass.id;
    this.router.navigate([rlink, { id: this.userClass.id }], {fragment: 'top'});
    } else {
        this.pageErrors = true;
        this.openDialog("Errors!Video");
        // document.getElementById("modal_button").click();
    }
      // console.log(this.userClassesTakenList);
      // console.log(typeof(this.userClassesTakenList[8 - 2].whenClassCompleted));
      // console.log(typeof(this.userClass.id));

   }
   get_handout() {
    {
      //popup modals for info
      //if not class one and prior class is not completed, message = You must complete class # first
      //if not class one and prior class is completed but have not waited 7 days, message = You must wait 7 days after completing class # before accessing the next
      //if prior class is completed but video is not completed, message = You must complete the class video first
      //if all is good go to handout
      let cont = this.getRestrictions(2);

      if (cont) {
      window.open(this.getHandout());
      } else {
        this.pageErrors = true;
        this.openDialog("Errors!Video");
          // ClassesComponent.openDialog();
      }

      
    }
   }
   get_quiz() { 
      //popup modals for info
      //if not class one and prior class is not completed, message = You must complete class # first
      //if not class one and prior class is completed but have not waited 7 days, message = You must wait 7 days after completing class # before accessing the next
      //if prior class is completed but video is not completed, message = You must complete the class video first
      //if all is good go to handout

      let cont = this.getRestrictions(3);

      if (cont) {
        let rlink = "/quiz/" + this.userClass.id;
        this.router.navigate([rlink, { id: this.userClass.id }], {fragment: 'top'});
      } else {
        this.pageErrors = true;
        this.openDialog("Errors!Video");
      }
    
   }

  getHandout() {
    // console.log(this.authService.returnCurrentUser().id);
     let classId = this.userClass.id;
     this.userClasses = {
       classId: classId,
       userId: this.authService.returnCurrentUser().id, //this.authService.returnCurrentUser().id,
       videoLenght: 0,
       videoWatched: 0,
       isQuizCompleted: false,
       isVideoCompleted: false,
       isHandoutCompleted : true,
       whenClassStarted: null,
       whenClassCompleted: null,
       isAvailable: false,
     };

     this.userClassesService.UpdateUserClassesTakenVideoCompleted(this.userClasses).subscribe(next => {
     //console.log(this.userClasses);
     }, error => {
       this.alertify.error(error);
     }, () => {
      //console.log("finish 1");
       // check if student finishes all classes
         this.userCourseDto = {
           userId: this.authService.returnCurrentUser().id,
           courseId: this.authService.returnCourseId(),
           isCourseComplete: this.authService.getCourseCompleted() 
         }
         //console.log("error 2");
         this.userClassesService.CheckAllClassesComplete(this.userCourseDto).subscribe((courseCompleted: number) => {
         // set percentage
         let percentageWatched = courseCompleted * 100 / 30;
         localStorage.setItem('percentage', percentageWatched.toString());
         //console.log("error 3");
         // this.userClassesService.returnProgress(percentageWatched, this.authService.returnCurrentUser().id);
         if (courseCompleted == 30) {
           // showmodal -send email
           //this.open(this.modalCourse);
           // check if there is not other update before
           if (this.authService.getCourseCompleted() == "false") {
             this.alertify.success('You have completed the course Intercessory Prayer !');
             this.authService.setCourseCompleted(); 
           }
         }
       }
       );
     }
     );
     //console.log("error 4");
    //  let checkHandout = document.getElementById("checkHandout" + classId.toString()) as HTMLImageElement;
    //  checkHandout.src = "../../assets/checkmark-check.png";
    this.userClassesTaken.isHandoutCompleted = true;
    //  https://fliphtml5.com/qubm/rlzm 
    //  return '../../assets/handout/Session_' + classId + '_Handout_Online.pdf';
    return 'https://fliphtml5.com/qubm/rlzm';
   }

   // include the character %
  getPercentageVideoWatchedText() {
    // this.listLenghtVideos.set(classTaken.id, classTaken.videoLenght);
    // this.listLenghtWatchedVideos.set(classTaken.classId, classTaken.videoWatched);

    let lenghtVideo = this.userClassesTaken.videoLenght;//this.listLenghtVideos.get(classId); 
    let videoWatched = this.userClassesTaken.videoWatched;//this.listLenghtWatchedVideos.get(classId);
    if (lenghtVideo != null && videoWatched != null && lenghtVideo != 0) {
      return ((videoWatched * 100) / lenghtVideo ).toFixed(0).toString()  + ' % ';
    }
  }

  getPercentageVideoWatched() {
    let lenghtVideo = this.userClassesTaken.videoLenght;
    let videoWatched = this.userClassesTaken.videoWatched;
    if (lenghtVideo != null && videoWatched != null && lenghtVideo != 0) {
      return (videoWatched * 100) / lenghtVideo;
    }
  }

  openDialog(s: string) {
    this.dialogRef.open(PopupComponent, {
      data : {
        pageErrorsText : this.pageErrorsText,
        pageErrorsText1 : this.pageErrorsText1,
        pageErrorsLink : this.pageErrorsLink
    }
  });
}

  // getCheckHandout() {
    // if (this.userClassesTaken.isHandoutCompleted ) { //.get(handoutId)) {
    //   return '../assets/checkmark-check.png';
    // }
    // else {
    //   return '../assets/checkmark-uncheck.png';
    // }
  // }

  // getCheckQuiz() {
   // console.log('listQuizCompleted' + this.listQuizCompleted.get(quizId));
    // if (this.userClassesTaken.isQuizCompleted) {//   .get(this.class_number)) {
    //   return '../../assets/checkmark-check.png';
    // }
    // else {
    //   return '../../assets/checkmark-uncheck.png';
    // }
  // }

}
