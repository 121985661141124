import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../models/user';
//import { AlertifyService } from '../services/alertify.service';
import { AlertifyService } from '../services/alertify.service';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Country } from '../models/country';
import { CountryService } from '../services/country.service';

import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CustomValidators } from '../shared/custom.validators';
// import { MyErrorStateMatcher } from '../shared/my_error_state_matcher';
import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators"

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css' ]
})
export class RegisterComponent implements OnInit {
  // fa
  filmIcon = faFilm;
  faEnvelopeIcon = faEnvelope;

  registerForm: FormGroup;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  user: User;
  countries: Country[];
  // matcher = new MyErrorStateMatcher();


  constructor(private alertify: AlertifyService,
    private fb: FormBuilder, private authService: AuthService, private countryService: CountryService,
    private router: Router) { }

  ngOnInit() {
    this.createRegisterForm();
    this.getCountryList();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]], 
      countryId: ['', Validators.required],
      city: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
    }, { validator: CustomValidators.compareTwoPasswords });
  }

//   buildForm(): void {
//     this.userForm = this.formBuilder.group({
//         passwords: this.formBuilder.group({
//             password: ['', [Validators.required]],
//             confirm_password: ['', [Validators.required]],
//         }, {validator: this.passwordConfirming}),

//     });
// }

  register() {
    if (this.registerForm.valid) {
      // move the vslues from this.registerForm.value to {}
      this.user = (<any>Object).assign({}, this.registerForm.value);
     // console.log(this.user);
      this.authService.register(this.user).subscribe(() => {
        this.alertify.success('Registration successful');
        this.router.navigate(['message-signup']);
      }, error => {
        console.log(error);
        this.alertify.error(error);
        //this.alertify.success('Registration successful');
      }, () => { // third part , complete, we nee to redirect the user once they have register
          this.router.navigate(['message-signup']);
      });
    }
  }

  getCountryList() {
    console.log('getCountryList');
    this.countryService.getCountriesList().subscribe((countries: Country[]) => {
      this.countries = countries;

      
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
        //set default country
        this.registerForm.controls['countryId'].setValue('US');
    }
    );
  }

}
