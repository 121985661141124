import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserCourseDto } from '../models/user-course-dto';

@Injectable({
  providedIn: 'root'
})
export class UserClassesService {
  baseUrl = environment.baseUrl + 'userclasses/';
  percentageObservable = new BehaviorSubject<number>(null); // Observable : list of reports
  dataPercentage = this.percentageObservable.asObservable(); //


  private navStateSource = new Subject<any>();
  navState$ = this.navStateSource.asObservable();



  constructor(private http: HttpClient) { }

  GetAllUserClasses() {
    let u = this.http.get(this.baseUrl + "getAllUserClasses");
    // console.log(u);
    //return this.http.get(this.baseUrl );
    return u;
    
  }

  getCountriesList() {
    let u = this.http.get(this.baseUrl );
    // console.log(u);
    //return this.http.get(this.baseUrl );
    return u;
  }

  UpdateAllUserClassesTaken(userclassestaken: any[]) {
    let result = this.http.post(this.baseUrl + 'UpdateAllUserClassesTaken', userclassestaken);
    return result;
    // return "back again";
  }


  UpdateUserClassesTakenVideo(userClassesTaken: any) {
    return this.http.post(this.baseUrl + 'UpdateUserClassesTakenVideo', userClassesTaken);
    //return this.http.post(this.baseUrl + 'UpdateUserClassesTakenVideo3', null);

  }

  getVideoWatched(userId: number, classId: number) {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    params = params.append('classId', classId.toString());
    return this.http.get(this.baseUrl + 'GetVideoWatched', { params });
  }

  UpdateUserClassesTakenVideoCompleted(userClassesTaken: any) {
    //Set one of the is...completed to true, logic in repository, if it is false set to the value given, if it is true dont change
    //if only one of these is set to true only one gets changed...
    //console.log('UpdateUserClassesTakenVideoCompleted :' + userClassesTaken.classId);
    //console.log(this.baseUrl + 'UpdateUserClassesTakenVideoCompleted');
    // console.log("handout_completed");
    // console.log(userClassesTaken);
    return this.http.post(this.baseUrl + 'UpdateUserClassesTakenVideoCompleted', userClassesTaken);
    //return this.http.post(this.baseUrl + 'UpdateUserClassesTakenVideo3', null);

  }

  //UpdateUserClassesTakenQuiz(userClassesTaken: any) {
  //  console.log('UpdateUserClassesTakenQuiz :' + userClassesTaken.classId);
  //  console.log(this.baseUrl + 'UpdateUserClassesTakenQuiz');

  //  return this.http.post(this.baseUrl + 'UpdateUserClassesTakenQuiz', userClassesTaken);
  //}

  //UpdateUserClassesTakenHandoutCompleted(userClassesTaken: any) {
      
  //  return this.http.post(this.baseUrl + 'UpdateUserClassesTakenHandoutCompleted', userClassesTaken);
  //}

  GetAllClassesTakenByUser(userId: number) {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    return this.http.get(this.baseUrl + 'GetAllClassesTakenByUser', { params });
  }

  getActiveClasses() {
    return this.http.get(this.baseUrl + 'GetActiveClasses');
  } 

   
  CheckAllClassesComplete(userCourseDto: UserCourseDto) {
    let params = new HttpParams();
   // params = params.append('userId', userId.toString());
    let courseComplete = userCourseDto.isCourseComplete == 'false' ? 0 : 1; 
    return this.http.get(this.baseUrl + 'CheckAllClassesComplete/' + userCourseDto.userId + "/" + userCourseDto.courseId + "/" + courseComplete );
  }

  GetAllClassesComplete(userCourseDto: UserCourseDto) {
    let params = new HttpParams(); 
   // params = params.append('userId', userId.toString());
    let courseComplete = userCourseDto.isCourseComplete == 'false' ? 0 : 1; 
    return this.http.get(this.baseUrl + 'GetAllClassesComplete/' + userCourseDto.userId + "/" + userCourseDto.courseId + "/" + courseComplete );
  }

  returnProgress(progress: number, userCourseDto: UserCourseDto) {
    // check if student finishes all classes
    this.CheckAllClassesComplete(userCourseDto).subscribe((courseComplete: number) => {
      // set percentage
      let percentageWatched = courseComplete * 100 / 30;
      localStorage.setItem('percentage', percentageWatched.toString());

      if (courseComplete == 30) {

      }
      else {
        //update the task bar
      }
    }
    );
    //localStorage.getItem("user")
    progress = parseInt(localStorage.getItem("percentage"));
    //console.log('progress' + progress);
    this.percentageObservable.next(progress);
  }

  GetCertificatePdf(userId: number) {
    return this.http.get(this.baseUrl + 'GetCertificatePdf/' + userId, { responseType: 'blob' })
  }
 
}
