import { AbstractControl, ValidatorFn, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";

export class CustomValidators {


  static compareTwoPasswords(g: FormGroup) {
    let password: string;
    let confirmPassword: string;

    password = g.get('password').value;
    confirmPassword = g.get('confirmPassword').value;
    //console.log(password == confirmPassword ? true : false);
    return password == confirmPassword ? null : { distinctPasswordsError: true };
  }

  static compareTwoDates(g: FormGroup) {
    let dateFrom: any;
    let dateTo: any;
    let datePipe: DatePipe = new DatePipe('en-US');

    dateFrom = g.get('dateFrom').value;
    dateTo = g.get('dateTo').value;

    if (g.get('dateTo').value == null || g.get('dateFrom').value == null) {
      return;
    }
    // get values Datepicker
    dateFrom = dateFrom.month + "/" + dateFrom.day + "/" + dateFrom.year;
    dateTo = dateTo.month + "/" + dateTo.day + "/" + dateTo.year;

    let dateFromValidation: Date;
    let dateToValidation: Date;

    dateFromValidation = new Date(dateFrom);
    dateToValidation = new Date(dateTo);

    /* let mayor: boolean;
    mayor = dateToValidation >= dateFromValidation ? false: true;
    console.log('mayor : ' + mayor);*/

    return dateToValidation >= dateFromValidation ? null : { 'dateError': true };


    // return dateTo >= dateFrom ? null : { 'dateError': true };
  }

  static ValidateRangeOfDates(dateFrom: any, dateTo: any, rangeOfDays: number) {
    let dateFromValidation: Date;
    let dateToValidation: Date;

    dateFromValidation = new Date(dateFrom);
    dateFromValidation.setDate(dateFromValidation.getDate() + rangeOfDays);
    dateToValidation = new Date(dateTo);

    if (dateFromValidation < dateToValidation) {
      return false;
    }

    return true;
  }

}
