import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-message-signup',
  templateUrl: './message-signup.component.html',
  styleUrls: ['./message-signup.component.css']
})
export class MessageSignupComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

  getEmailUser() {
    return this.authService.currentUser['email']
  }
}
