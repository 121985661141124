import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../services/alertify.service';
import { AuthService } from '../services/auth.service';
import { UserDto } from '../models/user-dto';
import { UserClassesService } from '../services/user-classes.service';
import { UserCourseDto } from '../models/user-course-dto';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loginForm: FormGroup;
  processing: boolean;
  userCourseDto: UserCourseDto; // to check if the course is completed

  constructor(private fb: FormBuilder, private router: Router,
    public authService: AuthService, private alertify: AlertifyService,
    private userClassesService: UserClassesService) { }

  ngOnInit() {
    this.createLoginForm();

    this.processing = true;
    if (this.authService.loggedIn()) {
      this.router.navigate(['/home']); 
    }

  }

  getUrl() {
    return "url('../assets/sky8.jpg')";
  }
  createLoginForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.model = Object.assign({}, this.loginForm.value);

    this.authService.login(this.model).subscribe(next => {
      this.alertify.success('logged in successfully');
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
      this.alertify.error(error); //  'Failed to loginsss');
    }, () => {

        // check percentaje
        // check if student finishes all classes
        this.userCourseDto = {
          userId: this.authService.returnCurrentUser().id,
          courseId: this.authService.returnCourseId(),
          isCourseComplete: 'true'  //true, just for not generate the certificate of complition, when the value is true it does not generate the certificate. 
        }

        this.userClassesService.CheckAllClassesComplete(this.userCourseDto).subscribe((courseCompleted: number) => {
          // set percentage
          let percentageWatched = courseCompleted * 100 / 30;
          localStorage.setItem('percentage', percentageWatched.toString());
          if (courseCompleted == 30) {
            this.authService.setCourseCompleted();
          }
        }
        );


      const userDto: UserDto = JSON.parse(localStorage.getItem('user'));

      //  Object.assign(user, userDto);
      if (!this.authService.currentUser['mustChangePassword']) {
        // everithign OK  -> should go to Menu 
        this.router.navigate(['/welcome']); 
      }
      else {
        this.alertify.error("User must change password");
        this.router.navigate(['/change-password', this.authService.returnCurrentUser().id]);
      }

    }
    );
  }

  register() {
    this.router.navigate(['/register']);
  }

}
