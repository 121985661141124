<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="head">
  <!-- this div can be overflow -->


  <div class="container-fluid  ">
    <div class="row image-background align-items-center background">
      <div class="col justify-content-center text-center">
        <h1 class="text text-white title">
          INTERCESSORY
        </h1>
        <h1 class="text text-white title">
          PRAYER
        </h1>

        <button class="button button3" (click)="learnMore()">Learn More</button>
      </div>
    </div>

  </div>
</div>



<div class="head">
  <!-- this div can be overflow -->


  <div class="container-fluid mx-0 px-0">

    <div class="row  align-items-center quote-div">
      <div class="col justify-content-center text-center">


        <blockquote class="wp-block-quote">
          <p class="mb-0">Jeremiah 29:11–13 <br /><br /></p>
          <p class="mb-0">For I know the thoughts that I think toward you, saith the LORD, thoughts of peace, and not of evil, to give you an expected end.  Then shall ye call upon me, and ye shall go and pray unto me, and I will hearken unto you.  And ye shall seek me, and find me, when ye shall search for me with all your heart.<br /><br /></p>
          <!-- <p class="mb-0">For I know the plans I have for you," declares the LORD, "plans to prosper you and not to harm you, plans to give you hope and a future. Then you will call on me and come and pray to me, and I will listen to you. you will seek me and find me when you seek me with all your heart.</p> -->
          <!-- <p class="mb-0">Isaiah 56:7 <br /><br /></p> -->
          <!-- <p class="mb-0">Even them will I bring to My holy mountain, and make them joyful in My house of prayer: their burnt offerings and their sacrifices shall be accepted upon Mine altar; for mine house shall be called an house of prayer for all people.</p> -->
        </blockquote>


      </div>
    </div>
  </div>
</div>

