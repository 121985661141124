<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
 <div class="container container_no_spaces" >
    <!--<i class="fa fa-user fa-5x"></i>
    <i class="fa fa-dashboard fa-5x"></i>
    <i class="fa fa-money fa-5x"></i>
    <i class="fa fa-home fa-5x"></i>
    <i class="fa fa-th fa-5x"></i>-->

    <div class="row justify-content-center text-center ">
      <div class="col col-sm-6 justify-content-center py-3">
        <h3>Change Password</h3>
      </div>
    </div>
    <div class="row justify-content-center  row-register2">
      <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
        <form class="container container_inner" [formGroup]="addToClassForm" (ngSubmit)="addToClass()">

          <div class="form-group">
            <label for="username">Email</label>
            <span class="input-group-addon"><i class="glyphicon glyphicon-envelope"></i></span>
            <input formControlName="username"
                   [ngClass]="{'is-invalid': addToClassForm.get('username').errors && addToClassForm.get('username').touched}"
                   type="email" class="form-control" id="username" placeholder="E-Mail Address">
            <div class="invalid-feedback" *ngIf="addToClassForm.get('username').touched && addToClassForm.get('username').hasError('required')">Username is requiered</div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input formControlName="password"
                   [ngClass]="{'is-invalid': addToClassForm.get('password').errors
                  && addToClassForm.get('password').touched}"
                   type="password" class="form-control" id="password">
            <div class="invalid-feedback" *ngIf="addToClassForm.get('password').hasError('required')
      && addToClassForm.get('password').touched">Password is required</div>
          </div>

          <div class="form-group">
            <label for="listofnames">List Of Names</label>
            <input formControlName="listofnames"
                   [ngClass]="{'is-invalid': addToClassForm.get('listofnames').errors
                  && addToClassForm.get('listofnames').touched}"
                   type="text" class="form-control" id="new-password">
            <div class="invalid-feedback" *ngIf="addToClassForm.get('listofnames').hasError('required')
      && addToClassForm.get('listofnames').touched">Paste list Of names separated by comma</div>
          </div>



          <div class=" text-center">
            <button type="button" class="btn btn-outline-primary" [disabled]="!addToClassForm.valid" type="submit">Add To Class</button>
            <button type="button" class="btn btn-outline-success ml-3" (click)="register()">Register</button>
            <!--button type="submit" class="btn btn-primary">Create Account</button>-->
          </div>
          <br />
          <div class=" text-center">
            <a [routerLink]="['/login-help']"><span class="font-weight-bold">Forgot Password</span></a>
            <span > | </span>
            <a [routerLink]="['/contact-us']"><span class="font-weight-bold text-dark">Need Help?</span></a>

          </div>

          <!--<button class="btn btn-primary" type="button" [disabled]= "this.processing" >
    <span *ngIf="this.processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Processing
  </button>-->


        </form>
      </div>

    </div>
  </div>
