



<div class="container container_no_spaces ">
  <div class="row justify-content-center  ">
    <div class="col mb-3">
      <div id="top"></div>

    </div>

  </div>

  <div class="container bg-light ">
    <div class="row bg-info ">

      <div class="col-12 col-md-10 mb-2">

        <span class="text-blue" style="font-size: 2rem;font-weight:bold"> Class {{this.class?.order}} - {{this.class?.smallTitle}} </span>
        <br />
        <span class="text-white" style="font-size: 1rem; font-weight:bold">{{this.class?.description}}</span>

      </div>

      <div class="col-12 col-md-2 my-auto">
        <a class="btn btn btn-light text-dark mr-2" [routerLink]="['/classes']">Return to <br /> Classes</a>
      </div>

    </div>

    <div class="row mt-2">
      <!-- video -->
      <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center bg-light">
        <video #videoClass id="videoClass" (timeupdate)="tickTimeVideo($event)" width="100%" height="auto"  controls controlsList="nodownload">
          <source src={{videoUrl}} type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>


    </div>

    <div class="row mt-3">
      <!-- steps -->
      <div class="col-lg-12 col-md-12 col-sm-12  bg-light">
        <p class="font-weight-bold">  </p>
        <!--<br />
        <p class="font-weight-bold"> Step 2: Complete the Quizz  </p>-->
      </div>

    </div>
  </div>
</div>

<script >

  function setCurrentTimeSafari() {
    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
    alert('isSafari2');
    if (!isSafari) {
      alert('isSafari');
      document.getElementById('videoClass').currentTime = userClassesTaken.videoWatched;
      // console.log('currentTime Javascript: ' + document.getElementById('videoClass').currentTime);
    }
  }

</script>