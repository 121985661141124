import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
//import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserClassesService } from '../../services/user-classes.service';
import { UserClassesTaken } from '../../models/user-classes-taken';
// import { UserCourseDto } from '../../models/user-course-dto';
import { AlertifyService } from '../../services/alertify.service';
import { ClassService } from '../../services/class.service';
import { classItem } from '../../models/classItem';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../popup/popup.component';
// import {List, Item} from 'linked-list'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})
export class ClassesComponent implements OnInit, AfterViewInit  { 
  videoUrl = '';
  height: number = 400; //default value
  @ViewChild('card2', { static: true }) MyDOMElement: ElementRef;
  testvar: string = "22";

  userClassesList: classItem[];  //list of all classes
  userClassesTakenList: UserClassesTaken[]; // list of classes completed by the student
  //userClassesList: [Class][UserClassesTaken];  
  userClassesListds = new Map();  //list of classes and reference classes completed by the student 
                                //userClassesListds.get(userClasses) = userClasseslist[10], userClassesList.get(userClassesTaken) = userClassesTakenList[10]


  clist: (classItem | UserClassesTaken)[][];
  // clist: any[];
  //containingList: any[10] = [[],[]],[[],[]];

  constructor( public authService: AuthService, private userClassesService: UserClassesService,
    private alertify: AlertifyService, public classService: ClassService, private dialogRef : MatDialog) { }

  ngOnInit() {
    //this.height = 0;
    this.videoUrl = 'https://lwccmediafilesstorage.blob.core.windows.net/mediafilescontainer/Intercessory_Prayer_Class_1.mp4?sv=2018-03-28&sr=b&sig=4UT1vqE5oywS9RBeqTX%2FaLCe7nA%2FU7%2BcJq%2F6aPWo4LA%3D&se=2020-12-17T03%3A10%3A19Z&sp=r';
    //script added to html
    //setTimeout(this.resizeDivs, 2600);
    // for(let i = 0 ; i < 10 ; i++ ) {
    //   let clist1: (Class | UserClassesTaken)[] = [];
    //   this.clist[i] = clist1;
    // }

    this.GetAllClassesTakenByUser(this.authService.returnCurrentUser().id);
    this.GetAllClasses();
  }

  ngAfterViewInit() {
  }

  openDialog() {
      this.dialogRef.open(PopupComponent, {
  	    data : {
  		    name : 'Samuel'
  		}
  	});
  }
  

  GetAllClasses() {
    this.classService.getAllClasses().subscribe((userClasses: classItem[]) => {
      this.userClassesList = userClasses;
      // this.userClassesListds.set("classes", this.userClassesList);
      // console.log(this.userClassesListds.get("classes")); 
      console.log("UserClassesList");
      console.log(this.userClassesList);

      // const item1 = new Item(this.userClasseslist[0]);
      // const item2 = new Item()
      // const list = new List(item1, item2)
      // const array = list.toArray()

      // for(var c of userClasses) {
      //   let i = 0;
      //   if (i < 10) {
      //     this.clist[i][0] = c;
      //   }
      //   i++;
      // }
      // console.log("1");
      // console.log(this.clist[1]);

      // this.containingList[0] = this.userClasseslist[0];
    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //do nothing
    }
    );
  }



  GetAllClassesTakenByUser(userId: number) {
    this.userClassesService.GetAllClassesTakenByUser(userId).subscribe((userClassesTakenList: UserClassesTaken[]) => {
      this.userClassesTakenList = userClassesTakenList;
      // this.userClassesListds.set("classesTaken", this.userClassesTakenList);
      //console.log(this.userClassesListds.get("classesTaken"));
      // console.log(userClassesTakenList);

      //get the number of classes that user has started
      let length = this.userClassesTakenList.length;

      this.userClassesTakenList.forEach(uct => {
        this.userClassesListds.set(uct.classId, uct);
      });

      //check if the user has not started any classes or if they have started all 10
      if (length == null || length < 10) {

        let uid = this.authService.returnCurrentUser().id
        //let classId = 0;
        // console.log("Pre: " + length);

        //increase to the first null class
        length++;
        

        //loop through all of the classesn and make sure the array is 10 elements long
        for (let i = 0 ; i < 10; i++) {
          let uct: UserClassesTaken;
          if (this.userClassesListds.has(i + 1)) {
            uct = this.userClassesListds.get(i + 1);
          } else {
            // initialize the first class after the last started one
            uct = {
              classId: i + 1,
              userId: uid, //this.authService.returnCurrentUser().id,
              videoLenght: 0,
              videoWatched: 0,
              isQuizCompleted: false,
              isVideoCompleted: false,
              isHandoutCompleted : false,
              whenClassStarted: null,
              whenClassCompleted: null,
              isAvailable: false,
            };
          }

          this.userClassesTakenList[i] = uct;

        }


        
        /*
        //loop through all of the classes
        for ( ; length <= 10; ) {
          // initialize the first class after the last started one
          let uct: UserClassesTaken = {
            classId: length,
            userId: uid, //this.authService.returnCurrentUser().id,
            videoLenght: 0,
            videoWatched: 0,
            isQuizCompleted: false,
            isVideoCompleted: false,
            isHandoutCompleted : false,
            whenClassStarted: null,
            whenClassCompleted: null,
            isAvailable: false,
          };

          // uct.classId = length;
          this.userClassesTakenList[length - 1] = uct;
          // console.log("post: " + length);
          length++;

        }*/

        // console.log(this.userClassesTakenList);


      // for(var c of userClassesTakenList) {
      //   let i = 0;
      //   if (i < 10) {
      //     this.clist[i][1] = c;
      //   }
      //   i++;
      // }
      // console.log("2");
      // console.log(this.clist[0]);
      }


    }, error => {
      this.alertify.error(error);
    }, () => { // third part , complete
      //do nothing
    }
    );
  }




  

}