import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators'; // add manually 

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl = environment.baseUrl + 'course/';
  constructor(private http: HttpClient) { }

  active: any;

  getCourseList() {
    return this.http.get(this.baseUrl );
  }

  isCourseActive() {
    return this.http.get(this.baseUrl + 'GetActiveCourse');
  }

  checkIfActive() {
    // console.log("Is this active?");
    this.isCourseActive().subscribe((activeList: boolean[]) => {

        // console.log( " activeList: " + activeList[0].toString());
    if(activeList[0].toString() != 'true') {
        // console.log("activeList[0].toString");
        return false;
      } else {
        return true;
      }   
    }, error => {
      //this.alertify.error(error); //  'Failed to loginsss');
    }
    );
  }

  setMaintenanceMode(ppp: string) {
    console.log("set Maintenance Mode");
    console.log(this.baseUrl + 'SetMaintenanceMode/' + ppp);
    return this.http.post(this.baseUrl + 'SetMaintenanceMode/' + ppp, null);
  }
   
   /*
    return this.http.get(this.baseUrl)
      .pipe(
        map((response: any) => {
          const res = response;
          if (res) {
            
            var course = JSON.stringify(res.res);
            var course1 = JSON.parse(course);
            console.log("course: " + course);
            console.log("course1: " + course1);

            localStorage.setItem('Course', course1.IsActive);

            if (course1.isActive == 1)
            {localStorage.setItem('ActiveCourse', 'true');}
            else
            {localStorage.setItem('ActiveCourse', 'false');}

          }
          else {
            console.log(response);
          }
        })
      ); 
  }

  GetActiveCourse() {
    this.isCourseActive();
    this.active = localStorage.getItem('ActiveCourse');
    console.log(this.active);
    if(this.active.toString() != 'true')
    {return false;}
    return true;
  }
  */

}
