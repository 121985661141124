<!--<div class="container " [ngStyle]="{'background-image': getUrl()}">-->
<div class="container container_no_spaces ">
    <div class="row justify-content-center  ">
      <div class="col mb-3">
        
      </div>

    </div>

    <div class="row justify-content-center  ">
      <div class="col col-sm-10  col-md-10  col-lg-10 justify-content-center">

        <div class="container container_inner2 mb-4  ">
          <div class="row justify-content-center text-center ">
            <div class="col col-sm-10 justify-content-center py-3">
              <h2>Hello {{ getNameUser()}}, </h2>
            </div>
          </div>
          <div class="row justify-content-center  row-register2 mb-4  " valing="top">
            <div class="col col-12  col-lg-5 justify-content-center visible-md-none .d-md-none " valign="top">
              <img class="visible-lg-block .d-md-none" style="padding-left: 10px;" src="../assets/BillVeronicaWinston.png" alt="" />
            </div>
            <div class="col col-12 col-lg-7 mt-1 justify-content-center  ">
              <span style="    font-size: 18px;  ">
                Welcome to our Intercessory Prayer Training hosted by the Prayer Ministry at Living Word Christian Center under the leadership of Drs. Bill & Veronica Winston.  It is a great joy to know that you are taking the time to invest in your spiritual growth and enhance your prayer life.
                The purpose of this training is to lay the foundation for success in prayer by applying the biblically based truths, tools and nuggets you will receive in these sessions.  As you embark on this new adventure, decide now that you will give your time, attention, energy and commitment to this training.
                
                This training consists of powerful video teachings, valuable lessons, articles to enhance your knowledge of prayer and assignments to reinforce what you have learned.  Read the prayer training manual and take the quiz for each class session.  We believe this training will empower your prayer life.

              </span>
              <br />
              <br />
              <button type="button" (click)="continueToClasses()" class="btn btn-primary text-white ">Watch video and take quiz</button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
