<div class="container container_no_spaces">
  <div class="row justify-content-center text-center ">
    <div class="col col-sm-6 justify-content-center py-3">
      <h3>Reset Password</h3>
    </div>
  </div>
  <div class="row justify-content-center  row-register2">
    <div class="col col-sm-10  col-md-8  col-lg-6 justify-content-center">
      <form class="container container_inner" [formGroup]="resetForm" (ngSubmit)="resetPassword()">


        <div class="form-group">
          <label for="password">Password</label>
          <input formControlName="password"
                 [ngClass]="{'is-invalid': resetForm.get('password').errors
                  && resetForm.get('password').touched}"
                 type="password" class="form-control" id="password">
          <div class="invalid-feedback" *ngIf="resetForm.get('password').hasError('required')
      && resetForm.get('password').touched">Password is required</div>
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input formControlName="confirmPassword"
                 [ngClass]="{'is-invalid': resetForm.get('confirmPassword').errors
                  && resetForm.get('confirmPassword').touched}"
                 type="password" class="form-control" id="confirmPassword">
          <div class="invalid-feedback" *ngIf="resetForm.get('confirmPassword').hasError('required')
      && resetForm.get('confirmPassword').touched">Confirm Password is required</div>
        </div>

        <div class=" text-center">
          <div class="invalid-feedback-form" *ngIf="resetForm.hasError('distinctPasswordsError')  && resetForm.get('password').dirty ">
            Password and Confirm Password must be the same
          </div>
          <button type="button" class="btn btn-outline-primary" [disabled]="!resetForm.valid" type="submit">Reset Password</button>
        </div>

      </form>
    </div>

  </div>
</div>
