<div id="page-top">
    <div id="wrapper">
      <!-- Add Navigation -->
      <admin-panel-nav></admin-panel-nav>
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                
                <div class="container-fluid">

                        <!-- {{ userForm.value | json}} -->
                        <hr />
                        <!-- {{ this.uclass | json }} -->

                        <h3 class="text-dark mb-4 ">Profile</h3>
                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <!-- <div class="card mb-3">
                                    <div class="card-body text-center shadow"><img class="rounded-circle mb-3 mt-4" src="assets/img/dogs/image2.jpeg" width="160" height="160">
                                        <div class="mb-3"><button class="btn btn-primary btn-sm" type="button">Change Photo</button></div>
                                    </div>
                                </div> -->
                                <div class="card shadow mb-4">
                                    <div class="card-header py-3 d-flex flex-row">
                                        <h6 class="text-primary fw-bold me-auto">User Progress</h6>

                                        <div *ngIf="!editMode; else notEditMode">
                                            <button class="btn btn btn-dark" (click)="editMode=true;">Edit Progress</button>
                                        </div>
                                          
                                        <ng-template #notEditMode>
                                            <button class="btn btn btn-dark" (click)="saveProgressChanges()">Save Changes</button>
                                        </ng-template>

                                    </div>

                                    
                                    <div class="card-body">

                                        <!-- Start Table -->
                                        <div class="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                            <table class="table my-0" id="dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>CLASS</th>
                                                        <th>VIDEO</th>
                                                        <th>HANDOUT</th>
                                                        <th>QUIZ</th>
                                                        <th>DATE AVAILABLE</th>
                                                        
                                                        <th>WHEN STARTED</th>
                                                        <th>WHEN COMPLETED</th>
                                                        <th>OVERRIDE AVAILABLE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
        
                                                    <tr *ngFor="let item of userClassesTakenList">
    
                                                        <!-- Class ID -->
                                                        <td>{{item.classId}}</td>
                                                        <!-- Video Completion -->
                                                        <td>
                                                            <div (click)="editMode ? item.isVideoCompleted = !item.isVideoCompleted : ''">
                                                              <div *ngIf="item.isVideoCompleted; else notFinishedVideo">
                                                                <img class="me-2" width="25" height="25" src="assets/img/check.png">
                                                              </div>
                                                                
                                                              <ng-template #notFinishedVideo>
                                                                <img class="me-2" width="25" height="25" src="assets/img/cancel.png">
                                                              </ng-template>
                                                              <p style="visibility: hidden">{{this.classCompleted}}</p>
                                                            </div>
                                                        </td>
                                                        <!-- Handout Completion -->
                                                        <td>
                                                            <div (click)="editMode ? item.isHandoutCompleted = !item.isHandoutCompleted : ''">
                                                                  <div *ngIf="item.isHandoutCompleted; else notFinishedHo">
                                                                    <img class="me-2" width="25" height="25" src="assets/img/check.png">
                                                                  </div>
                                                                    
                                                                  <ng-template #notFinishedHo>
                                                                    <img class="me-2" width="25" height="25" src="assets/img/cancel.png">
                                                                  </ng-template>
                                                                  <p style="visibility: hidden">{{this.classCompleted}}</p>
                                                            </div>
                                                            
                                                        </td>
                                                        <!-- Quiz Completion -->
                                                        <td>
                                                            <div (click)="editMode ? item.isQuizCompleted = !item.isQuizCompleted : ''">
                                                                  <div *ngIf="item.isQuizCompleted; else notFinishedQz">
                                                                    <img class="me-2" width="25" height="25" src="assets/img/check.png">
                                                                  </div>
                                                                    
                                                                  <ng-template #notFinishedQz>
                                                                    <img class="me-2" width="25" height="25" src="assets/img/cancel.png">
                                                                  </ng-template>
                                                                  <p style="visibility: hidden">{{this.classCompleted}}</p>
                                                            </div>
                                                            
                                                        </td>
                                                        <!-- Date this class is available -->
                                                        <td>{{getAvailableDate(item)}}</td>
                                                        <!-- Date this class was started -->
                                                        <td>{{getStartedDate(item)}}</td>
                                                        <!-- Date this class was completed -->
                                                        <td>{{getCompletedDate(item)}}</td>
                                                        <!-- Override the available date to make this class available -->
                                                        <td>
                                                            <mat-slide-toggle
                                                                    [checked]="item.isAvailable === true ? true : false"
                                                                    (change)="editMode ? item.isAvailable = !item.isAvailable : ''"
                                                                    [disabled]="!editMode"
                                                                    class="example-margin"
                                                                    [color]="color">
                                                                    <div *ngIf="item.isAvailable ">Overide Set</div>
                                                                    <div *ngIf="!item.isAvailable ">No Override</div>
                                                            </mat-slide-toggle>
                                                        </td>
                                                        
                                                         
         
                                                    </tr>

                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>CLASS</th>
                                                        <th>VIDEO</th>
                                                        <th>HANDOUT</th>
                                                        <th>QUIZ</th>
                                                        <th>DATE AVAILABLE</th>
                                                        
                                                        <th>WHEN STARTED</th>
                                                        <th>WHEN COMPLETED</th>
                                                        <th>OVERRIDE AVAILABLE</th>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <!-- End Table -->

                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-8">


                                <div class="row">
                                    <div class="col" *ngIf="userProfForm != null">
                                        <div class="card shadow mb-3">
                                            <div class="card-header py-3">
                                                <p class="text-primary m-0 fw-bold">User Settings</p>
                                            </div>
                                            <div class="card-body">
                                                <form [formGroup]="userProfForm" (ngSubmit)="saveUser()">
                                                    <div class="row">

                                                        <div class="col">
                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="id"><strong>Id Number: {{user?.id}}</strong></label>
                                                                <!-- <input formControlName="id"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('id').errors && userProfForm.get('id').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="id" placeholder="{{user?.id}}" value="{{user?.id}}" > -->
                                    
                                                                <!-- <div class="invalid-feedback" *ngIf="userProfForm.get('id').touched && userProfForm.get('id').hasError('required')">Username is requiered</div> -->
                                                    
                                                            </div>
                                                        </div>

                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="username"><strong>Username</strong></label>
                                                                <input formControlName="username"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('username').errors && userProfForm.get('username').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="username" placeholder="{{user?.email}}" value="{{user?.email}}" >
                                    
                                                                <div class="invalid-feedback" *ngIf="userProfForm.get('username').touched && userProfForm.get('username').hasError('required')">Username is requiered</div>
                                                    
                                                            </div>

                                                        </div>

                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="email"><strong>Email Address</strong></label>
                                                                <input formControlName="email"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('email').errors && userProfForm.get('email').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="email" placeholder="{{user?.email}}" value="{{user?.email}}" >
                                                                
                                                                <div class="invalid-feedback" *ngIf="userProfForm.get('email').touched && userProfForm.get('email').hasError('required')">Email is requiered</div>
                                                    
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        
                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="firstname"><strong>First Name</strong></label>
                                                                <input formControlName="firstname"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('firstname').errors && userProfForm.get('firstname').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="firstname" placeholder="{{user?.firstName}}" value="{{user?.firstName}}" >
                                                                
                                                                <div class="invalid-feedback" *ngIf="userProfForm.get('firstname').touched && userProfForm.get('firstname').hasError('required')">Email is requiered</div>
                                                    
                                                            </div>

                                                        </div>
                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="lastname"><strong>Last Name</strong></label>
                                                                <input formControlName="lastname"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('lastname').errors && userProfForm.get('lastname').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="lastname" placeholder="{{user?.lastName}}" value="{{user?.lastName}}" >
                                                                
                                                                <div class="invalid-feedback" *ngIf="userProfForm.get('lastname').touched && userProfForm.get('lastname').hasError('required')">Last Name is requiered</div>
                                                    
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="city"><strong>City</strong></label>
                                                                <input formControlName="city"
                                                                       [ngClass]="{'is-invalid': userProfForm.get('city').errors && userProfForm.get('city').touched}"
                                                                       type="text" class="form-control form-control-sm"  id="city" placeholder="{{user?.city}}" value="{{user?.city}}" >
                                                                
                                                                <div class="invalid-feedback" *ngIf="userProfForm.get('city').touched && userProfForm.get('city').hasError('required')">Last Name is requiered</div>
                                                    
                                                            </div>

                                                        </div>
                                                        <div class="col">

                                                            <div class="form-group d-inline"> 
                                                                <label class="form-label" for="countryId"><strong>Country</strong></label>
                                                                       <select formControlName="countryId" class="form-control" id="countryId" placeholder="Country">
                                                                        <option value="" disabled selected hidden>Country ...</option>
                                                                        <option *ngFor="let item of this.countries" [ngValue]="item?.id">{{item?.name}}</option>
                                                                      </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mb-3">
                                                        <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    <form [formGroup]="userPassForm" (ngSubmit)="savePass()">
                        <div class="row">
                            <div class="col" *ngIf="userPassForm != null">
                                <div class="form-group d-inline" style="width: 100%"> 
                                    <!--<label for="firstname " class="form-label">Firts Name</label>-->
                                    <label class="form-label" for="pass"><strong>Change Password</strong></label>
                                    <!-- <label class="form-label"><span class="input-group-text">Change Password</span></label> -->
                                    <input formControlName="pass" style="display: inline;" 
                                           [ngClass]="{'is-invalid': userPassForm.get('pass').errors && userPassForm.get('pass').touched}"
                                           type="password" class="form-control form-control-sm"  id="pass" placeholder="XXXXXX" value="" >
                                    
                                    <div class="invalid-feedback" *ngIf="userPassForm.get('pass').touched && userPassForm.get('pass').hasError('required')">Password is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group d-inline" style="width: 100%"> 
                                    <!--<label for="firstname " class="form-label">Firts Name</label>-->
                                    <label class="form-label" for="email1"><strong>Email</strong></label>
                                    <!-- <label class="form-label"><span class="input-group-text">Email</span></label> -->
                                    <input formControlName="email1" style="display: inline; width: inherit;"
                                           [ngClass]="{'is-invalid': userPassForm.get('email1').errors && userPassForm.get('email1').touched}"
                                           type="text" class="form-control form-control-sm"  id="email1" placeholder="{{user?.email}}" value="" >
                                    
                                    <div class="invalid-feedback" *ngIf="userPassForm.get('email1').touched && userPassForm.get('email1').hasError('required')">Email is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="my-3">
                            <button class="btn btn-primary btn-sm" type="submit">Save Settings</button>
                            <!-- <button class="btn btn-primary"  type="submit">Submit</button> -->
                        </div>
                        
                    </form>
                    


                </div>
            </div>
        </div><a class="border rounded d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</div>