import { Component, OnInit } from '@angular/core';
import { AlertifyService } from '../services/alertify.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { UserClassesService } from '../services/user-classes.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  urlCertificate: string;

  constructor(public authService: AuthService,
    private alertify: AlertifyService, private userClassesService: UserClassesService) { }

  ngOnInit() {
    
  }

  existsCertificate() {
    if (this.authService.getCourseCompleted() == 'false') {
      return false;
    }
    return true;
  }


  downloadCertificate() {
    // console.log("Course Completed: " + this.authService.getCourseCompleted());
    // console.log("Click" );
    if (this.authService.getCourseCompleted() == 'true') {
      let userId = this.authService.returnCurrentUser().id;

      this.userClassesService.GetCertificatePdf(userId).subscribe(fileData => {
        console.log(fileData);
        saveAs(fileData, 'certificate.pdf');

        let blob: Blob = new Blob([fileData], { type: 'application/ pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(blob);
        //   return;
        // }
      }, error => {
        this.alertify.error(error); //  'Failed to loginsss');
      }, () => {
        //  this.router.navigate(['/login']);
      }
      );
    }
  }

}
