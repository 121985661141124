import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ReportService {

  baseUrl = environment.baseUrl + 'report/';

  constructor(private http: HttpClient) { }

  getStats(ppp: string) {
    return this.http.get(this.baseUrl + 'getStats/' + ppp);
  }


  exportAllExcel(secret: string, days: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}exportAllExcel/${secret}`, { 
          params: { days: days.toString() },
          responseType: 'blob' 
      }).pipe(
      catchError(error => {
        console.error('Error downloading the file', error);
        throw error;
      })
    );
  }

  /*exportNewExcel(secret: string, days: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}exportNewExcel/${secret}`, {
            params: { days: days.toString() },
            responseType: 'blob' 
        }).pipe(
        catchError(error => {
            console.error('Error downloading the file', error);
            throw error;
        })
    );
  }*/

  exportCompleteExcel(secret: string, days: number): Observable<Blob> {
      console.log("Export Complete Excel");
      console.log(`${this.baseUrl}exportCompleteExcel/${secret}`)
      console.log(days.toString())
      console.log(secret)
      return this.http.get(`${this.baseUrl}exportCompleteExcel/${secret}`, { 
            params: { days: days.toString() },
            responseType: 'blob' 
          }).pipe(
          catchError(error => {
              console.error('Error downloading the file', error);
              throw error;
          })
      );
  }

  exportInactiveExcel(secret: string, days: number): Observable<Blob> {
      console.log("Export Inactive Excel");
      console.log(`${this.baseUrl}exportInactiveExcel/${secret}`)
      console.log(days.toString())
      console.log(secret)
      return this.http.get(`${this.baseUrl}exportInactiveExcel/${secret}`, { 
            params: { days: days.toString() },
            responseType: 'blob' 
          }).pipe(
          catchError(error => {
              console.error('Error downloading the file', error);
              throw error;
          })
      );
  }
  
  /*exportNewExcel(ppp: string) {
    return this.http.post(this.baseUrl + 'exportNewExcel/' + ppp, '');
  }
  exportCompleteExcel(ppp: string) {
    return this.http.post(this.baseUrl + 'exportCompleteExcel/' + ppp, '');
  }
  exportInactiveExcel(ppp: string) {
    return this.http.post(this.baseUrl + 'exportInactiveExcel/' + ppp, '');
  }*/

}
