<body>
  <app-nav-menu [ngStyle]="{'background-image': getUrl()}"></app-nav-menu>
  <!-- <app-size-detector></app-size-detector> -->
  <!--<div class="container" >-->
    <!-- <button id="modal_button" (click)="openDialog()" class="btn btn-primary">
      Click to open popup
  </button> -->
  <style>
    .container-fluid {
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 0px;
      margin-left: 0px;
    }
  </style>
  <div class="content-outer-container">
    <div class="content-inner-container">
  <div class="container-fluid background-main-image" [style.background-image]="'url(/assets/sky8.jpg)'" style="width: 100%">
    <div class="row ">
      <div class="col col-sm-12 justify-content-center ">

        <router-outlet style="width: 100%" (activate)="onActivate($event)"></router-outlet>

      </div>
    </div>
  </div>
</div>
</div>

<div class="footer-container" >
  <app-footer></app-footer>
</div>
</body>

