import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.css']
})
export class ConstructionComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router ) { }

  ngOnInit() {
    //console.log('cthis.currentUser: ' + this.authService.currentUser);
  }

  getNameUser() {
    //return this.authService.currentUser['email']
    let currentUser = this.authService.returnCurrentUser();
    return currentUser.firstName + ' ' +  currentUser.lastName;
  }

  continueToClasses() {
    this.router.navigate(['/classes']);
  }

}
