
<footer class="bg-primary text-white text-center text-lg-start ">
  
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
          <p class="mb-0">Isaiah 56:7 <br /><br /></p>
          <!-- <h5 class="text-uppercase">King James Bible</h5> -->
          <p class="mb-0">Even them will I bring to My holy mountain, and make them joyful in My house of prayer: their burnt offerings and their sacrifices shall be accepted upon Mine altar; for mine house shall be called an house of prayer for all people.</p>

        

        <!-- <p>
          Even them will I bring to my holy mountain, and make them joyful in my house of prayer: their burnt offerings and their sacrifices shall be accepted upon mine altar; for mine house shall be called an house of prayer for all people.
        </p> -->
      </div>

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        
      </div>

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Links</h5>

        <ul class="list-unstyled mb-0">
          <li>
            <a target="_blank" href="https://www.livingwd.org/" class="text-white">Living Word Christian Center</a>
          </li>
          <li>
            <a target="_blank" href="https://online-classes.livingwd.org/" class="text-white">Foundations Online</a>
          </li>
          <li>
            <a target="_blank" class=" text-white " href="https://lwsom.org/">Living Word Bible Training Center </a>
          </li>
          <li>
            <a target="_blank" href="https://jbs.edu/" class="text-white">Joseph Business School</a>
          </li>
          <li>
            <a class=" text-white " [routerLink]="['/contact-us']">Contact Us</a>
          </li>

        </ul>
      </div>
      <!--Grid column-->
      <!--<div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase mb-0">Links</h5>

        <ul class="list-unstyled">
          <li>
            <a href="#!" class="text-white">Link 1</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 2</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 3</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 4</a>
          </li>
        </ul>
      </div>-->
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->
  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © {{ this.year }} Copyright:
    <a class="text-white" href="https://www.livingwd.org">Living Word Christian Center</a>
  </div>
  <!-- Copyright -->
</footer>
